import { useMemo } from 'react';
import { useLocation } from "react-router-dom";
import styles from "./Book.module.css"
import BookDetails from "../components/BookDetails.jsx"
import SimilarBooks from "../components/SimilarBooks"
import abstract from "../images/abstract-2.svg"
import IFrameWrapper from '../components/IFrameWrapper';
import TranslationText from '../components/TranslationText';
import { GetBook } from "../features/database/booksTable.js"

function Book() {
    let query = useQuery();
    var bookId = query?.get("book");
    if (!bookId)  
    {
      var loc = window.location.href.split('/');
      bookId = loc[loc.length - 1];
    }
    const book = GetBook(bookId);

   if (!book) {
        return(<div><h1><TranslationText value="loading_all_capital" /></h1></div>)
    } else {
        if (!book)
            return(<div><h1><TranslationText value="no_books_found_text"/></h1></div>)
        return(
          <div className={styles.main}>
            <BookDetails book={book}/>
            <div className={styles.separator}></div>
            <SimilarBooks book={book}/>
            <div className={styles.abstract}>
              <img src={abstract} alt="abstract"/>
            </div>
            <IFrameWrapper book={book} bookId={book?.id} />
          </div>
          )
      }
}

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}


export default Book;