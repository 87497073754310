export function GetAllLogin()
{
    var data = {
        access_token: localStorage.getItem('access_token'),
        refresh_token: localStorage.getItem('refresh_token'),
        user: JSON.parse(localStorage.getItem('user')),
        activeUserId: localStorage.getItem('activeUserId')
    }

    return data;
}

export async function SaveLogin(data)
{
    localStorage.setItem('hasUser', data.user ? 'true' : 'false');
    localStorage.setItem('access_token', data.access_token);
    localStorage.setItem('refresh_token', data.refresh_token);
    localStorage.setItem('user', JSON.stringify(data.user));
    localStorage.setItem('activeUserId', data.activeUserId);
}

export async function DeleteLogin()
{
    localStorage.removeItem('hasUser');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
    localStorage.removeItem('activeUserId');
}

export async function UpdateUser(userId)
{
    localStorage.setItem('activeUserId', userId);
}