import classLogo from "../images/BOOKR-Class-logo.svg";
import styles from './Navbar.module.css';
import searchIcon from '../images/search.png'
import searchSelectedIcon from '../images/search-selected.svg'
import React from "react";
import { useSearchParams } from 'react-router-dom';
import LevelSelector from "./LevelSelector";
import { useNavigate } from 'react-router-dom';
import ProfileDashboardButton from "./ProfileDashboardButton";

function Navbar({hideSearch, hideLevelSelector = true})
{ 
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();
    const handleClickLogo = () => {
        navigate('/', {replace: false});
      };
      
    const handleClickSearch = () => {
        searchParams.delete("book")
        navigate("/search?" + searchParams.toString(), {replace: false});
      };
    //<img src={avatar} alt="avatar-background" />

    return (
        <div className={styles.bg_class}>
            <nav className={styles.navbar}>
                <div className={styles.logo} onClick={handleClickLogo}>
                    <img src={classLogo} width="90" height="43" alt="BOOKR Class"/>
                </div>
                <div className={styles.level} hidden={hideLevelSelector}>
                    <LevelSelector/>
                </div>
                <div className={styles.login}> 
                    <ProfileDashboardButton/>
                </div>
                <div className={styles.searchIcon} onClick={handleClickSearch}>
                    <img src={hideSearch ? searchSelectedIcon : searchIcon} alt="searchIcon"/>
                </div>
            </nav>
        </div>
    )
}


export default Navbar;