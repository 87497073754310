
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector, useDispatch } from 'react-redux';
import selectIcon from "../images/select-icon.svg";
import { setLevelId } from '../features/search/searchSlice.js'
import styles from "./LevelSelector.module.css";
import dropdownImage from "../images/arrow_down.svg";
import { GetLevels } from "../features/database/terms.js"
import { Translate } from "../features/database/uiTexts.js";
import { GetHiddenLevels } from "../features/database/booksTable.js";
import TranslationText from "./TranslationText";

export default function LevelSelector() {

    const dispatch = useDispatch();
    const levelId = useSelector((state) => state.search.levelId);
    const allLevels = GetLevels();
    const hiddenLevels = GetHiddenLevels();

    const levels = allLevels && hiddenLevels ? allLevels.filter(lvl => !hiddenLevels.some(hiddenLvl => lvl.id === hiddenLvl.id)) : [];  

    const level_text = Translate("level_text");
    const all = Translate("all");
    var style = styles.levelSelector;
    if (levelId !== 0)
        style += " " + styles.levelSelected;
    return (<Dropdown onSelect={(event_key, event) => {
        console.log(event_key);
        dispatch(setLevelId(event_key));
    }} >
        <Dropdown.Toggle bsPrefix={style} >
            <img src={selectIcon} alt="" className={styles.selectIcon} />
            {levelId !== 0 ?
                level_text?.value + " " + levels?.find(level => level.id === levelId)?.title
                : all?.value + " " + level_text?.value}
            <img src={dropdownImage} alt="" className={styles.dropdownImage} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <Dropdown.Item eventKey={0} className={styles.dropdownItem}><TranslationText value="all" /> <TranslationText value="level_text" /></Dropdown.Item>
            {levels
                ? levels.map(level => (<Dropdown.Item eventKey={level.id} key={level.id} className={styles.dropdownItem}><TranslationText value="level_text" /> {level.title}</Dropdown.Item>))
                : (<Dropdown.Item eventKey="0"><TranslationText value="loading_all_capital" /></Dropdown.Item>)}
        </Dropdown.Menu>
    </Dropdown>);
}