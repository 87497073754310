import styles from "./HomeFilters.module.css";
import all from "../images/all.svg";
import allSelected from "../images/all-selected.svg";
import cont from "../images/continue.svg";
import contSelected from "../images/continue-selected.svg";
import favourites from "../images/favourites.svg";
import favouritesSelected from "../images/favourites-selected.svg";
import interests from "../images/interests.svg";
import interestsSelected from "../images/interests-selected.svg";
import myBooks from "../images/my-books.svg";
import myBooksSelected from "../images/my-books-selected.svg";
import { useSelector, useDispatch } from 'react-redux'
import HomeTabIcon from "./HomeTabIcon"
import { setHomeTab } from "../features/search/searchSlice"
import HomeTabDropdown from "./HomeTabDropdown";

export default function HomeFilters() {
    const homeTab = useSelector((state) => state.search.homeTab);
    const activeUser = useSelector((state) => state.user.activeUser);
    const dispatch = useDispatch()
    const OnClick = (id) => {
        dispatch(setHomeTab(id))
    }
    if (!activeUser || activeUser.type !== "child")
    {
        dispatch(setHomeTab(0))
        return null;
    }
    return (
        <div>
            <div className={styles.Main}>
                <HomeTabIcon selected={homeTab === 0}
                    icon={all} iconSelected={allSelected}
                    value="all" OnClick={() => OnClick(0)} />
                <HomeTabIcon selected={homeTab === 1}
                    icon={myBooks} iconSelected={myBooksSelected}
                    value="my_books" OnClick={() => OnClick(1)} />
                <HomeTabIcon selected={homeTab === 2}
                    icon={interests} iconSelected={interestsSelected}
                    value="topic_interests" OnClick={() => OnClick(2)} />
                <HomeTabIcon selected={homeTab === 3}
                    icon={favourites} iconSelected={favouritesSelected}
                    value="favourites" OnClick={() => OnClick(3)} />
                <HomeTabIcon selected={homeTab === 4}
                    icon={cont} iconSelected={contSelected}
                    value="continue" OnClick={() => OnClick(4)} />
            </div>
            <div className={styles.MainSmall}>
                <HomeTabDropdown OnClick={OnClick} homeTab={homeTab} />
            </div>
        </div>
        )
}