//import Masonry from 'react-masonry-css'
import BookCard from './BookCard'
import React from "react";

import { Masonry } from '@mui/lab';
import { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux'
import styles from "./BookList.module.css"
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LoadingElement from './LoadingElement'
import NoBookFound from "./NoBookFound"
import { GetBooksFilteredCount, GetBooksFiltered } from "../features/database/booksTable.js"

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 992,
      lg: 1200,
      xl: 1400,
    },
  },
});

function BookList() {
  const [limit, setLimit] = useState(25);

  const searchParsed = useSelector((state) => state.search.parsed);
  const termId = useSelector((state) => state.search.termId);
  const levelId = useSelector((state) => state.search.levelId);
  const activeUser = useSelector((state) => state.user.activeUser);

  const total = GetBooksFilteredCount(searchParsed, termId, levelId);
  const books = GetBooksFiltered(searchParsed, termId, levelId);
  
  useEffect(() => {
    setTimeout(() => {
      setLimit(25);
    }, 100);
  }, [searchParsed, termId, levelId])


  const intObserver = useRef();
  const lastPostRef = useCallback(post => {
    if (intObserver.current) intObserver.current.disconnect();

    intObserver.current = new IntersectionObserver(posts => {
      if (posts[0].isIntersecting && limit < total) {
        setTimeout(() => {
          setLimit(limit + 25);
        }, 100)
      }
    });

    if (post) intObserver.current.observe(post);
  }, [limit, total])

  var content = books?.slice(0, limit).map((book, i) => (<BookCard book={book} key={book.id} bookUserDatas={book.bookUserData} index={i} userId={activeUser?.id}/>));
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.water_container}>
        <div className={styles.box_group}>
          {!books ? (<LoadingElement />) 
            : books.length === 0
            ? (limit < total ? null : <NoBookFound />)
            : (<Masonry columns={{ xs: 2, sm: 3, md: 4, lg: 6, xl: 6 }} spacing={0}>{content}</Masonry>)}
        </div>
        <div ref={lastPostRef} hidden={!books || limit >= total}>
          {books && limit < total ? (<LoadingElement />) : null}
        </div>
      </div>
    </ThemeProvider>);
}

export default BookList;