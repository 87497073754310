import plurallLogo from "../images/plurall_logo.png";
import kolibriLogo from "../images/kolibri_img_logo.jpg";
import classeraLogo from "../images/classera_logo.png";
import styles from "./LoginPanel.module.css"
import { loginWithUsernameAndPassword } from "../features/users/login.js"
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TranslationText from './TranslationText.jsx';
import { openWindow } from "../features/windows/windowSlice";
import { ValidatePassword, ValidateEmailOrUsername } from '../features/validation/validation.js'
import InputErrorMessage from "./InputErrorMessage";
import { Translate } from "../features/database/uiTexts.js";
import ClasseraIFrameWrapper from "./ClasseraIFrameWrapper.jsx";
import { openClasseraLogin } from "../features/webgl/classeraWebView.js";

export default function LoginPanel() {
    var [ userName, SetUserName ] = useState("");
    var [ password, SetPassword ] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ errorMessage, SetErrorMessage ] = useState("");
    const [ errorMessageSSO, SetErrorMessageSSO ] = useState("");

    const input_username_or_email_wrong_format = Translate("input_username_or_email_wrong_format");
    const input_password_wrong_format = Translate("input_password_wrong_format");
    const login_error = Translate("login_error");
    
    const onSubmitLogin = (event) => {
        // console.log(userName);
        if (!ValidateEmailOrUsername(userName))
        {
            SetErrorMessage(input_username_or_email_wrong_format.value);
            event.preventDefault();
            return false;
        }
        // console.log(password);
        if (!ValidatePassword(password))
        {
            SetErrorMessage(input_password_wrong_format.value);
            event.preventDefault();
            return false;
        }
        loginWithUsernameAndPassword(dispatch, { userName, password }, (result, message) => {
            console.log(result)
            if (result)
            {
                SetErrorMessage("")
                navigate('/', {replace: false});
            } else {
                SetErrorMessage(login_error.value)
            }
        });
        event.preventDefault();
    }

    const onSubmitClasseraSSOLogin = (event) => {

        dispatch(openClasseraLogin());

        event.preventDefault();
    }

    const onSubmitPlurallSSOLogin = (event) => {
        const isStaging = window.location.href.includes("app-staging.");
        const plurallLink =  !isStaging ? "https://sso.plurall.net/oauth/authorize?response_type=code&client_id=NjM3YmIyNDllOTZkZjFi&redirect_uri=https%3A%2F%2Fapi.v2.bookrclass.com%2Fapi%2Foauth%2Fsso%2Fapp%2Flogin%2Fplural%2F%3FreturnUrl%3Dhttps%3A%2F%2Fbookrclass.com%2F%3Fplatform%3Dweb" : "https://sso.plurall.net/oauth/authorize?response_type=code&client_id=NjM3YmIyNDllOTZkZjFi&redirect_uri=https%3A%2F%2Fapi.v2.bookrclass.com%2Fapi%2Foauth%2Fsso%2Fapp%2Flogin%2Fplural%2F%3FreturnUrl%3Dhttps%3A%2F%2Fbookrclass.com%2F%3Fplatform%3Dwebstg";
        window.location.replace(plurallLink);
        event.preventDefault();
    }

    const onSubmitKolibriSSOLogin = (event) => {
        const isStaging = window.location.href.includes("app-staging.");
        const kolibriLink =  !isStaging ? "http://onepasslearning.com/?partner_redirect_uri=https://app.bookrclass.com/" : "http://onepasslearning.com/?partner_redirect_uri=https://app-staging.bookrclass.com/";
        window.location.replace(kolibriLink);
        event.preventDefault();
    }

    const onForgotPassword = () => {
        const targetPage = "/reset-password";
        dispatch(openWindow({title: "pgate_header", params: {targetPage}, type: "parentalGate", Small: true, subTitle: "please_enter_valid_data_error_text"}))
    }

    const onRegister = () => {
        const targetPage = "/register";
        dispatch(openWindow({title: "pgate_header", params: {targetPage}, type: "parentalGate", Small: true, subTitle: "please_enter_valid_data_error_text"}))
    }

    const onSignInWithStudentCode = () => {
        const targetPage = "/code-sign-in";
        navigate(targetPage);
    }

    const textUserName = Translate("input_username");
    const textPassword = Translate("input_password");
    const textLoginButton = Translate("login_submit");
    const textLoginCodeButton = Translate("login_use_code_long");
    const textLoginSSOPluralButton = Translate("login_use_plural");
    const textLoginSSOKolibriButton = Translate("login_use_kolibri");
    const textLoginSSOClasseraButton = Translate("login_use_classera");

    return (
        <div className={styles.Main}>
            <div className={styles.Title}><TranslationText value="login_header"/></div>
            <div>
                <form className={styles.DefaultLogin} onSubmit={onSubmitLogin}>
                    <InputErrorMessage message={errorMessage} />
                    <div className={errorMessage ? styles.WithError : ""}>
                        <input id="username" name="username" placeholder={textUserName?.value} onChange={(event) => {
                            SetUserName(event.target.value);
                            SetErrorMessage("");
                        }} autoComplete="username"/>
                    </div>
                    <div className={errorMessage ? styles.WithError : ""}>
                        <input id="password" name="password" placeholder={textPassword?.value} type="password" onChange={(event) => {
                            SetPassword(event.target.value)
                            SetErrorMessage("");
                        }} autoComplete="current-password"/>
                    </div>
                    <div className={styles.FormSubmit}>
                        <div className={styles.ForgotPassword} onClick={onForgotPassword}><TranslationText value="login_forgot"/></div>
                        <input className={styles.SignIn} type="submit" value={textLoginButton?.value}/>
                    </div>
                </form>
            </div>

            <form onSubmit={onSignInWithStudentCode}>
                <button type="submit" className={styles.SignInCodeButton}>
                    {textLoginCodeButton?.value}
                </button>
            </form>

            <InputErrorMessage message={errorMessageSSO} />
            <form className={styles.SSOLogins} onSubmit={onSubmitKolibriSSOLogin} >
                <button type="submit" className={styles.KolibriBtn}>
                    {/* <img src={kolibriLogo} alt="Kolibri Education Logo" className={styles.SSOLogoImg_Kolibri} /> */}
                    {textLoginSSOKolibriButton?.value}
                </button>
            </form>

            <InputErrorMessage message={errorMessageSSO} />
            <form className={styles.SSOLogins} onSubmit={onSubmitPlurallSSOLogin} >
                <button type="submit" className={styles.PlurallBtn}>
                    {/* <img src={plurallLogo} alt="Plurall-Somos Logo" className={styles.SSOLogoImg} /> */}
                    {textLoginSSOPluralButton?.value}
                </button>
            </form>

            {/* <InputErrorMessage message={errorMessageSSO} />
            <form className={styles.SSOLogins} onSubmit={onSubmitClasseraSSOLogin} >
                <button type="submit" className={styles.SSOButtons}>
                    <img src={classeraLogo} alt="Classera Logo" className={styles.SSOLogoImg} />
                    {textLoginSSOClasseraButton?.value}
                </button>
            </form> */}
            
            <div className={styles.ForgotPassword} onClick={onRegister}><TranslationText value="registration_header"/></div>

            <ClasseraIFrameWrapper />
        </div>
    )
}