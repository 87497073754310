import TranslationText from "./TranslationText"
import styles from "./UserTypeSelector.module.css"
import ParentImg from "../images/home-illustration.png"
import TeacherImg from "../images/school-illustration.png"

export default function UserTypeSelector({selector}) {
    return(<div  className={styles.Main}>
        <div className={styles.Title}>
            <TranslationText value="usertype_header" />
        </div>
        <div className={styles.Types}>
            <div className={styles.Type}>
                <img src={ParentImg} alt="ParentIcon"/>
                <div className={styles.Button} onClick={() => selector("Teacher")}>
                    <TranslationText value="usertype_teacher" />
                </div>
            </div>
            <div className={styles.Type}>
                <img  src={TeacherImg} alt="TeacherIcon"/>
                <div className={styles.Button + " " + styles.Parent} onClick={() => selector("Adult")}>
                    <TranslationText value="usertype_parent" />
                </div>
            </div>
        </div>
    </div>)
}