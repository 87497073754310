import { configureStore } from '@reduxjs/toolkit'
import searchReducer from './features/search/searchSlice.js'
import databaseReducer from './features/database/databaseSlice.js'
import webglReducer from './features/webgl/webgl.js'
import classeraWebViewReducer from './features/webgl/classeraWebView.js'
import userReducer from './features/users/userSlice.js'
import languageReducer from './features/language/language.js'
import windowReducer from './features/windows/windowSlice.js'

export default configureStore({
  reducer: {
    search: searchReducer,
    database: databaseReducer,
    webgl: webglReducer,
    classeraWebView: classeraWebViewReducer,
    user: userReducer,
    language: languageReducer,
    windows: windowReducer
  },
})