import styles from "./HelpBlock.module.css"
import arrowDown from "../images/dropdown-arrow.svg"
import arrowUp from "../images/dropdown-arrow-up.svg"

export default function HelpBlock({ category, questions, isOpen, OnClick }) {
    if (isOpen)
        return(<div className={styles.Main} onClick={OnClick}>
            <div className={styles.Category}>{category}</div>
            <img src={arrowUp} alt="drowdownArroDown" className={styles.Dropdown}/>
            <div className={styles.List}>
                {questions.map((item, index) => (<div key={index}>
                    <div className={styles.Question}>{item.question}</div>
                    <div className={styles.Answer}>{item.answer}</div>
                    </div>))}
            </div>
        </div>)
    return (
    <div className={styles.Main} onClick={OnClick}>
        <div className={styles.Category}>{category}</div>
        <img src={arrowDown} alt="drowdownArroDown" className={styles.Dropdown}/>
    </div>)
}