import { SaveBookUserDataToDB } from "../database/bookUserDataTable.js";

export function GetBookProgress(book, bookUserData) {
    if (!bookUserData || !(bookUserData.pagesVisited) || bookUserData.pagesVisited.length === undefined)
      return 0;
    var count = book.numberOfPages;
    var help = bookUserData.pagesVisited.length;
    var pageProgress = count / help;
    var timeCount = bookUserData.timeSpent;
    var time = book.narrationTime * 0.6;
    var timeProgress = timeCount / time;
    return Math.min(pageProgress, timeProgress);
}

export function HasBook(item)
{
  return item.book && item.book.length !== 0;
}

export function IsContinues(bookUserData)
{
  return HasBook(bookUserData) && GetBookProgress(bookUserData.book, bookUserData) < 1;
}

export async function fetchBlackList(dispatch, accessToken, userId)
{
  try {
    var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + accessToken);
      
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };
      const response = await fetch(process.env.REACT_APP_APIBASE + "mobile/books/blacklist?&userId=" + userId, requestOptions);
      const data = await response.json();


      console.log("data res: " + JSON.stringify(data));
      const bookIds = data.result.bookIds;
      var key = userId + "_blacklist";
      localStorage.setItem(key, JSON.stringify(bookIds));
  } catch(excpetion) {
    console.error(excpetion);
  }
}

export async function fetchBookUserData(dispatch, accessToken, userId) {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", "Bearer " + accessToken);
      
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      const response = await fetch(process.env.REACT_APP_APIBASE + "mobile/child/" + userId + "/bookUserData", requestOptions);
      const data = await response.json();
      const list = data.result.list;
      list.forEach(item => {
        item.id = item.bookId + "_" + userId;
        item.favourite = item.favourite ? 1 : 0;
        item.readLater = item.readLater ? 1 : 0;
        item.assignedByTeachers = item.assignedByTeachers ? 1 : 0;
        item.parentRecommended = item.parentRecommended ? 1 : 0;
        item.userId = userId;
      });
      await SaveBookUserDataToDB(dispatch, userId, list);
    } catch(excpetion) {
      console.error(excpetion);
    }
  }

export async function SaveBookUserData(dispatch, bookUserData, accessToken, onReturned) {
    try {
        const userId = bookUserData.userId;
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + accessToken);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(bookUserData);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const response = await fetch(process.env.REACT_APP_APIBASE + "mobile/child/" + userId + "/bookUserData/" + bookUserData.bookId, requestOptions);
        const errorMessage =  { code : 500, message : "Save Failed" };
        if (!response.ok) throw errorMessage;

        await fetchBookUserData(dispatch, accessToken, userId);
        if (onReturned)
          onReturned(true, "Logged In");
    } catch (error) {
        console.log('error', error);
        onReturned(false, error.message);
    };
}