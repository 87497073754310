export function filter(book, search, termId, levelId) {
    return isString(book, search) && isTerm(book, termId) && isTerm(book, levelId);
}

export function filterHome(book, levelId, intrests, homeTab) {
    if (homeTab === 2)
        return filterByInterests(book, intrests) && isTerm(book, levelId);
    return isTerm(book, levelId);
}

export function filterBookByLevel(book, levelId) {
    return isTerm(book, levelId);
}

export function filterBookUserData(bookUserData, homeTab) {
    if (homeTab === 0)
        return true;
    if (homeTab === 1)
        return bookUserData.assignedByTeachers;
    if (homeTab === 3)
        return bookUserData.favourite;
    if (homeTab === 4)
        return bookUserData.timeSpent > 0;
    return true;
}

function filterByInterests(book, interests) {
    if (!interests) return false;
    for (var id of interests)
        if (isTerm(book, id)) return true;
    return false;
}

function isString(book, search) {
    if (isEmptyOrSpaces(search)) return true;
    var searchable = convertString(book.search);
    return searchable.includes(search);
}

function isTerm(book, termId) {
    var termIdInt = parseInt(termId);
    return !termId || book.terms?.includes(termIdInt);
}

function isEmptyOrSpaces(str) {
    return str === null || str.match(/^ *$/) !== null;
}

export function convertString(str) {
    return str.toLowerCase().replace(/[^a-z0-9]/gi, '');
}