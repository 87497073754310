import styles from "./ResetPassword.module.css"
import Logo from "../images/BOOKR-Class-logo-color.svg"
import AbstractYellow from "../images/abstract-7.svg"
import AbstractBlue from "../images/abstract-8.svg"
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { resetPassword } from "../features/users/resetPassword"
import warningImage from "../images/warning.svg"
import { openWindow } from "../features/windows/windowSlice";
import TranslationText from '../components/TranslationText';
import { ValidateEmail } from '../features/validation/validation.js'
import { Translate } from "../features/database/uiTexts.js";

export default function ResetPassword()  {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/', {replace: false});
      };

      var [ email, SetEmail ] = useState("");
      const [ errorMessage, SetErrorMessage ] = useState("");
      
      const input_email_wrong_format = Translate("input_email_wrong_format");
      
      const onSubmitPasswordReset = (event) => {
          console.log(email);
          if (!ValidateEmail(email))
          {
            SetErrorMessage(input_email_wrong_format.value);
            event.preventDefault();
            return false;
          }
          resetPassword( email , (result, message) => {
              console.log(result)
              if (result)
              {
                  SetErrorMessage("")               
                  dispatch(openWindow({title: "pwd_recovery_modal_title", Small: true, subTitle: "pwd_recovery_ok"}))
                  navigate('/login', {replace: false});
              } else {
                  SetErrorMessage(message)
              }
          });
          event.preventDefault();
      }
  
    const onReturnToLogin = () => {
        SetErrorMessage("")
        navigate('/login', {replace: false});
    }

    
    const emailPlaceholder = Translate("registration_email_input_required");
    const submitButtonText = Translate("reset_my_password");

    const hasLoggedInUser = () => localStorage.getItem('hasUser') === 'true';
  
    return (<div>
                { hasLoggedInUser() ? <Navigate to="/" /> :
                <div>
                    <div className={styles.Logo} onClick={handleClick}>
                        <img src={Logo} alt="Bookr Class Logo"/>
                    </div>
                    <div className={styles.Main}>
                        <div className={styles.Title}><TranslationText value={"forgot_your_password"}/></div>
                        <div className={styles.description}><TranslationText value={"to_reset_your_password_please_enter"} /></div>
                        <div>
                            <form className={styles.DefaultLogin} onSubmit={onSubmitPasswordReset}>
                                <div className={styles.WarningMessage} hidden={!errorMessage}>
                                    <div className={styles.warningLogo}>
                                        <img src={warningImage} alt="warning"/>
                                    </div>
                                    <div><TranslationText value={errorMessage}/></div>
                                </div>
                                <div className={errorMessage ? styles.WithError : ""}>
                                    <input type="email" id="email" name="email" placeholder={emailPlaceholder?.value} onChange={(event) => {
                                        SetEmail(event.target.value);
                                        SetErrorMessage("");
                                    }} autoComplete="email"/>
                                </div>
                                <input className={styles.ResetPassword} type="submit" value={submitButtonText?.value}/>
                                
                                <div className={styles.ReturnToLogin} onClick={onReturnToLogin}><TranslationText value={"go_to_login"} /></div>
                            </form>
                        </div>
                    </div>
                    <div className={styles.AbstractBlue}>
                        <img src={AbstractBlue} alt=""/>
                    </div>
                    <div className={styles.AbstractYellow}>
                        <img src={AbstractYellow} alt=""/>
                    </div>
                </div>
                }
            </div>)
}