
import avatar from "../images/avatar.svg";
import styles from "./Avatar.module.css";
import { useSelector } from 'react-redux';

function convertToLetters(string) {
    var list = string.split(' ');
    var mapped = list.map(element => element[0])
    return mapped.join('');
}

export default function Avatar({big}) {
    const activeUser = useSelector((state) => state.user.activeUser);
    var mediaUrls = activeUser.avatar?.mediaUrls;
    if (activeUser.type !== "child")
        mediaUrls = null;

    return (<div className={styles.Main + " " + (big ? styles.Big : "")}>
        <img src={avatar} alt="avatar-background" className={styles.background}/>
        {mediaUrls ? <img src={big ? mediaUrls.original : mediaUrls.original_s} 
                        alt="avatar" className={styles.selected}/> 
            : <div className={styles.selected}>{convertToLetters(activeUser.name)}</div>}
    </div>)
}