import styles from "./SimilarBooks.module.css"
import { Masonry } from '@mui/lab';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import BookCard from './BookCard'
import { useEffect, useState } from "react";
import TranslationText from './TranslationText.jsx';
import { GetSimilarBooks } from "../features/database/booksTable.js"

const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 992,
        lg: 1200,
        xl: 1400,
      },
    },
  });

export default function SimilarBooks({book, displayAWStyle = null, closeWindowOnClick = false, isUltraSmallMobile = false}) {

  let displayedBookCount = isUltraSmallMobile ? 2 :3;

  const [limit, setLimit] = useState(15);
  const books = GetSimilarBooks(book, limit, displayedBookCount);

  let renderXSValue = displayAWStyle ? 3 : 2;

  useEffect(() => {
    if (books && books.length < 3) {
      setLimit(limit => limit - 1);
    }
  }, [books])
    const content = !books ? 
        (<div><TranslationText value="loading_all_capital"/></div>)
    :   (<ThemeProvider theme={theme}>
            <Masonry columns={{ xs: renderXSValue, sm: 3, md: 3, lg: 3, xl: 3 }} spacing={0} defaultHeight={100}>
                {books?.map((book, i) => (<BookCard book={book} key={book.id} small index={i} displayAWStyle={displayAWStyle ? displayAWStyle  :  false}  closeWindowOnClick />))}
            </Masonry>
        </ThemeProvider>) ;
    return(
    <div className={styles.main}>
        {!displayAWStyle ? <div className={styles.title}><TranslationText value="similar_books"/></div> : ""}
        <div className={styles.bookList}>
            {content}
        </div>
    </div>
    )
}