import styles from "./Login.module.css"
import LoginCodePanel from "../components/LoginCodePanel"
import Logo from "../images/BOOKR-Class-logo-color.svg"
import Abstract from "../images/abstract-3.svg"
import { Navigate, useNavigate } from 'react-router-dom';

export default function Login()  {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/', {replace: false});
      };

    const hasLoggedInUser = () => localStorage.getItem('hasUser') === 'true';

    return (<div>
                { hasLoggedInUser() ? <Navigate to="/" /> :
                <div className={styles.Main}>
                    <div className={styles.Logo} onClick={handleClick}>
                        <img src={Logo} alt="Bookr Class Logo"/>
                    </div>
                    <LoginCodePanel/>
                    <div className={styles.Abstract}>
                        <img src={Abstract} alt="Bookr Class Logo"/>
                    </div>
                </div>
                }
            </div>)
}