import { db } from "./db.js";
import { useLiveQuery } from "dexie-react-hooks";
import { filter, filterHome} from "../search/search.js"
import { setBooks } from "./databaseSlice.js";
import { useSelector } from "react-redux";
import store from "../../store.js";

function GetBlacklist()
{
  const userId = JSON.parse(localStorage.getItem("user"))?.id.toString();
  const key = userId + "_blacklist";
  const blacklist = localStorage.getItem(key);
  return blacklist ? JSON.parse(blacklist) : undefined;
}

function GetWhitelist()
{
  const allowedBooks = localStorage.getItem("allowedBooks");
  return allowedBooks ? JSON.parse(allowedBooks) : undefined;
}

export function GetHiddenLevels()
{
  const user = useSelector((state) => state.user.activeUser);

  if (user === null || user.type.toLowerCase() !== "child") {
    return [];
  } else {
    return user.hiddenLevels ? user.hiddenLevels : [];
  }
}

function FilterAllBook(book, blacklist, whitelist, hiddenLevels)
{
  return FilterWhitelistMatch(book, whitelist) && FilterBlackListMatch(book, blacklist) && FilterUsersHiddenLevels(book, hiddenLevels);
}

function FilterBlackListMatch(book, blacklist) {
  return !blacklist || !blacklist.includes(book?.id);
}
function FilterWhitelistMatch(book, whitelist) {
  return !whitelist || whitelist.includes(book?.id);
}
function FilterUsersHiddenLevels(book, hiddenLevels) {
  
  if (!hiddenLevels || (Array.isArray(hiddenLevels) && hiddenLevels.length === 0)) {
    return true;
  } else {

    let isBookAllowed = true;

    hiddenLevels.forEach(hiddenLevelObj => {
      if (book.terms.includes(hiddenLevelObj.id)) {
        isBookAllowed = false;
        return isBookAllowed;
      }
    });

    return isBookAllowed;
  }
}

export function GetBooksFilteredCount(searchParsed, termId, levelId)
{
  var blackList = GetBlacklist();
  var whiteList = GetWhitelist();
  var hiddenLevels = GetHiddenLevels();
  var dbData = useLiveQuery(() => 
    db?.books.filter(book => filter(book, searchParsed, termId, levelId) && FilterAllBook(book, blackList, whiteList, hiddenLevels)).count()
    ,[searchParsed, termId, levelId])
  var stateData = useSelector((state) => 
    state.database.books.filter(book => filter(book, searchParsed, termId, levelId) && FilterAllBook(book)).length)
  return db ? dbData : stateData;
}

export function GetBook(bookId)
{
  var dbData =  useLiveQuery(() => db?.books.get(parseInt(bookId)), [bookId]);
  var stateData = useSelector((state) => state.database.books.filter(book => book.id === Number.parseInt(bookId)));
  return db ? dbData : stateData[0];
}

export function GetAllBooks()
{
  if (db)
      return db?.books.toArray();
  var state = store.getState();
  return state.database.books;
}

export function GetBooksCount(homeTab)
{
  var blackList = GetBlacklist();
  var whiteList = GetWhitelist();
  var hiddenLevels = GetHiddenLevels();
  var dbData = useLiveQuery(() => db?.books.filter(book => FilterAllBook(book, blackList, whiteList, hiddenLevels)).count());
  var stateData = useSelector((state) => state.database.books.filter(book => FilterAllBook(book, blackList, whiteList, hiddenLevels)).length);
  return db ? dbData : stateData;
}

export function GetBooksFiltered(searchParsed, termId, levelId)
{
  var blackList = GetBlacklist();
  var whiteList = GetWhitelist();
  var hiddenLevels = GetHiddenLevels();
  var dbData = useLiveQuery(
        () => db?.books
            .filter(book => filter(book, searchParsed, termId, levelId) && FilterAllBook(book, blackList, whiteList, hiddenLevels))
            .with({ bookUserData: 'bookUserData' }), [searchParsed, termId, levelId]);

  var stateData = useSelector((state) => state.database.books
    .filter(book => filter(book, searchParsed, termId, levelId) && FilterAllBook(book, blackList, whiteList, hiddenLevels)));
  var stateBookUserDatas = useSelector(state => state.database.bookUserData);
  if (stateData && stateBookUserDatas && !db)
    for (let id in stateData)
    {
      stateData[id] = { 
        bookUserData: stateBookUserDatas.filter(data => data.bookId === stateData[id].id), 
        ...stateData[id] 
      };
    }
  
  return db ? dbData : stateData;
}

export function GetBooksFilteredHome(levelId, homeTab, activeUser)
{
  var blackList = GetBlacklist();
  var whiteList = GetWhitelist();
  var hiddenLevels = GetHiddenLevels();
  var dbData = useLiveQuery(
        () => {
          if (homeTab !== 0 && homeTab !== 2)
            return [];
          return db?.books
            .filter(book => filterHome(book, levelId, activeUser?.interestedTopics, homeTab) && FilterAllBook(book, blackList, whiteList, hiddenLevels))
            .with({ bookUserData: 'bookUserData' });
        }, [levelId, homeTab, activeUser]
      );
  var stateData = useSelector((state) => state.database.books
      .filter(book => filterHome(book, levelId, activeUser?.interestedTopics, homeTab) && FilterAllBook(book, blackList, whiteList, hiddenLevels)));
  var stateBookUserDatas = useSelector(state => state.database.bookUserData);
  if (stateData && stateBookUserDatas)
    for (let id in stateData)
    {
      stateData[id] = { 
        bookUserData: stateBookUserDatas.filter(data => data.bookId === stateData[id].id), 
        ...stateData[id] 
      };
    }
  if (homeTab !== 0 && homeTab !== 2)
    return [];
  return db ? dbData : stateData;
}

export function GetSlideShowBooks(levelId)
{
  var blackList = GetBlacklist();
  var whiteList = GetWhitelist();
  var hiddenLevels = GetHiddenLevels();
  var dbData = useLiveQuery(
        () => {
          return db?.books.filter(book => book.isFeatured && book.featuredImageUrls && filter(book, null, null, levelId) && FilterAllBook(book, blackList, whiteList, hiddenLevels)).toArray()
        }, [levelId]);
  var stateData = useSelector((state) => 
    state.database.books.filter(book => book.isFeatured && book.featuredImageUrls && filter(book, null, null, levelId) && FilterAllBook(book, blackList, whiteList, hiddenLevels)));
  return db ? dbData : stateData;
}

function similar(current, book, limit, blackList, whiteList, hiddenLevels) {
    let count = 0;
    for (const term of book.terms) {
      count += current.terms.includes(term);
    }
    return count > limit && current.id !== book.id && FilterAllBook(book, blackList, whiteList, hiddenLevels);
  }

export function GetSimilarBooks(book, limit, displayedBookCount)
{

  var blackList = GetBlacklist();
  var whiteList = GetWhitelist();
  var hiddenLevels = GetHiddenLevels();

  var books = useLiveQuery(() => {
      return db?.books.filter(current => similar(current, book, limit, blackList, whiteList, hiddenLevels)).limit(3).toArray()
  }, [limit, book, displayedBookCount]);
  var booksState = useSelector((state) => state.database.books);
  var booksFiltered = booksState.filter(current => similar(current, book, limit)).slice(0, 3);
  if (db)
      return books;
  return booksFiltered;
}

// export function GetSimilarBooks(book, limit, displayedBookCount)
// {
//   var blackList = GetBlacklist();
//   var whiteList = GetWhitelist();
//   var hiddenLevels = GetHiddenLevels();
//   var dbData =  useLiveQuery(
//         () => {
//           return db?.books.filter((current) => similar(current, book, limit) && FilterAllBook(book, blackList, whiteList, hiddenLevels)).limit(displayedBookCount).toArray()
//         }, [book, limit, displayedBookCount])
//   var stateData = useSelector((state) => 
//     state.database.books.filter((current) => similar(current, book, limit) && FilterAllBook(book, blackList, whiteList, hiddenLevels)).slice(0, displayedBookCount));

//   var unfilteredDbData = useLiveQuery(
//     () => {
//       return db?.books.filter((current) => current.id !== book.id).limit(displayedBookCount).toArray()
//     }, [book, limit, displayedBookCount]);

//   var unfilteredStateData = useSelector((state) => 
//     state.database.books.filter((current) => current.id !== book.id).slice(0, displayedBookCount));

//   var similarCollection = null;

//   var filteredSimilarCollection = db ? dbData : stateData;
//   var unfilteredSimilarCollection = db ? unfilteredDbData : unfilteredStateData;

//   // If there are no similar books based on filters, we pick randomly some
//   if (filteredSimilarCollection === undefined || filteredSimilarCollection === null || filteredSimilarCollection.length <= 0) {
//     similarCollection = unfilteredSimilarCollection;
//   }
//   else {
//     similarCollection = filteredSimilarCollection;
//   }

//   return similarCollection;
// }

export async function SaveBooks(dispatch, list)
{
    dispatch(setBooks(list));
    if (db) await db.books.bulkPut(list);
}