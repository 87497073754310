
import Navbar from '../components/Navbar'
import ProfileDetails from "../components/ProfileDetails"
import Interests from "../components/Interests"
import Badges from "../components/Badges"
import styles from "./Profile.module.css"
import { useSelector, useDispatch } from 'react-redux';
import { refreshMe } from "../features/users/login"
import { useEffect } from "react"
import { Navigate } from 'react-router-dom'

export default function Profile() {

    const dispatch = useDispatch();
    const accessToken = useSelector((state) => state.user.accessToken);
    
    useEffect(() => {
        if (accessToken && dispatch)
        {   
            const timer = setTimeout(() => {
                refreshMe(dispatch, accessToken, null);
            }, 500);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [accessToken, dispatch])

    const hasLoggedInUser = () => localStorage.getItem('hasUser') === 'true';

    return (<div>
                { !hasLoggedInUser() ? <Navigate to="/login" /> :
                <div>
                    <Navbar/>
                    <ProfileDetails/>
                    <div className={styles.Details}>
                        <Interests/>
                        <Badges/>
                    </div>
                </div>
                }
            </div>)
}