export async function resetPassword(email, onReturned){
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");


    var raw = JSON.stringify({"email": email});
    console.log(raw);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    fetch(process.env.REACT_APP_APIBASE + "mobile/password_recovery", requestOptions)
        .then(response => {
            switch (response.status){
                case 200: 
                    onReturned(true, "pwd_recovery_ok");
                    break;
                case 422:
                    //amikor nincs regisztrálva az email address
                    onReturned(false, "pwd_recovery_failed");
                    break;
                case 429:
                    //ha egymás után küldesz többször is ugyanarra az email címre (asszem 2 percen belül másodikat küldöd)
                    onReturned(false, "too_many_requests");
                    break;
                default:
                    //bármi más
                    onReturned(false, "something_went_wrong_wait_and_try_again");
                    break;
            }
        })
    .catch(error => {
        console.log('error', error);
        onReturned(false, error.message);
    });
}