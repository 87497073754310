import { createSlice } from '@reduxjs/toolkit'
import { convertString } from './search.js';

export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    value: "",
    parsed: "",
    termId: 0,
    levelId: 0,
    homeTab: 0,
  },
  reducers: {
    setString: (state, action) => {
      state.value = action.payload;
      state.parsed = convertString(action.payload);
    },

    setTermId: (state, action) => {
      state.termId = parseInt(action.payload);
    },

    setLevelId: (state, action) => {
      state.levelId = parseInt(action.payload);
    },

    setHomeTab: (state, action) => {
      state.homeTab = parseInt(action.payload);
    },

    reset: (state) => {
      state.value = "";
      state.parsed = "";
      state.termId = 0;
      state.levelId = 0;
      state.homeTab = 0;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setString, setTermId, setLevelId, setHomeTab, reset } = searchSlice.actions

export default searchSlice.reducer