
import LoadingElement from '../../components/LoadingElement'
import styles from "./AvatarEditWindow.module.css"
import TranslationText from "../../components/TranslationText"
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux';
import { saveMe } from "../../features/users/login"
import { closeWindow } from "../../features/windows/windowSlice.js"

export default function AvatarEditWindow() {

    const [loadingBadges, SetLoadingBadges] = useState(0);
    const [badges, SetBadges] = useState([]);
    const [error, SetErrors] = useState("");

    fetch(process.env.REACT_APP_APIBASE + "mobile/avatars")
    useEffect(() => {
        if (loadingBadges !== 0) return;
        SetLoadingBadges(1);
        fetch(process.env.REACT_APP_APIBASE + "mobile/avatars")
            .then(res => res.json())
            .then(
                async (data) => {
                    try {
                        SetBadges(data.result.list)
                        SetLoadingBadges(2);
                    } catch (error) {
                        SetErrors(error);
                    }
                },
                (error) => {
                    SetErrors(error);
                }
            )
    }, [loadingBadges]);


    const dispatch = useDispatch();
    const activeUser = useSelector((state) => state.user.activeUser);
    const accessToken = useSelector((state) => state.user.accessToken);
    const [selectedAvatarId, setSelectedAvatarId] = useState(activeUser?.avatar?.id, [activeUser])
    const onSave = (event) => {
        event.preventDefault();
        dispatch(closeWindow());
        var saveableUser = { id: activeUser.id, avatarId: selectedAvatarId }
        saveMe(dispatch, saveableUser, accessToken, (data) => console.log(data))
    }
    var [content, setContent] = useState([]);

    useEffect(() => {
        setTimeout(() => {
          setContent(badges.map(item =>
            <div className={styles.Item} onClick={() => setSelectedAvatarId(item.id)}>
                <div className={(item.id === selectedAvatarId ? styles.Selected : null)}>
                    <img src={item.mediaUrls.original_xl} decoding="sync" loading='eager' alt="avatar" />
                </div>
            </div>
        ))
        }, 100);
      }, [badges])
    return (
        <div>
            <div className={styles.List}>
                {error ?? null}
                {!content || content.length === 0 ? <LoadingElement/> : content}
            </div>
            <div className={styles.SaveChanges} onClick={onSave}><TranslationText value="save_changes" /></div>
        </div>
    )
}