import searchIcon from "../images/search-icon.svg"
import React from "react";
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { setString, setTermId } from '../features/search/searchSlice.js'
import styles from './SearchContainer.module.css'
import TranslationText from "./TranslationText";
import { GetTopics, GetLevel } from "../features/database/terms.js"
import { Translate } from "../features/database/uiTexts.js";

function SearchContainer() {
    const searchString = useSelector((state) => state.search.value);
    const termId = useSelector((state) => state.search.termId);
    const levelId = useSelector((state) => state.search.levelId);
    const [search, setSearch] = useState(searchString);
    const dispatch = useDispatch()

    const level = GetLevel(levelId);
    const topics = GetTopics();
    //search_field_placeholder
    const textSearch = Translate("search_field_placeholder");
    return (
        <div className={styles.fakeNav}>
            <div className={styles.searchContainer}>
                <img src={searchIcon} alt=""/>
                <input type="search" className={styles.search+" form-control"}  placeholder={textSearch?.value} 
                aria-label="" 
                aria-describedby="basic-addon1"
                value={search}
                onChange={(e) => {
                    setSearch(e.target.value);
                }}
                onBlur={() => dispatch(setString(search))}
                onKeyDown={(event) => {
                        if(event.key === 'Enter') {
                            dispatch(setString(search));      
                        }}
                    }
                 />

                <select className={styles.topicSelector + " form-select"} aria-label=".form-select-lg" 
                    onChange={(e) => dispatch(setTermId(e.target.value))}
                    value={termId}>
                    <option value={0}><TranslationText value="dsnew_see_all_topics" /></option>
                    {topics ? topics
                    .filter(item => !level || level.terms.includes(item.id))
                    .map(topic => (<option key={topic.id} value={topic.id}>{topic.title}</option>)) : (<option><TranslationText value="loading_all_capital" /></option>)}
                </select>
            </div>
            </div>
        )
    
}


export default SearchContainer;