import styles from "./BookDetails.module.css";
import BackButton from "../images/left-icon.svg";
import abstract from "../images/abstract-1.svg";
import { useNavigate } from 'react-router-dom';

export default function BookDetailsCover({ book }) {

    const coverUrl = book?.coverUrls?.original_xl;
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/', {replace: false});
        window.location.reload();
    };

    return (
        <div className={styles.cover}>
            <div className={styles.backButton} onClick={handleClick}>
                <img src={BackButton} alt="back-button"></img>
            </div>
            <img src={abstract} alt={book.title} className={styles.abstract}></img>
            <div className={styles.book_cover}>
                <img src={coverUrl} alt={book.title}></img>
                <div className={styles.overlay}></div>
            </div>
        </div>);
}