import styles from "./HomeTabIcon.module.css"
import TranslationText from "./TranslationText"

export default function HomeTabIcon({ selected, icon, iconSelected, value, OnClick }) {
    return (
        <div
            className={styles.IconHolder + (selected ? " " + styles.Selected : "")}
            onClick={OnClick}>
            <img src={(selected ? iconSelected : icon)} alt="icon" />
            <div className={styles.Text}>
                <TranslationText value={value} />
            </div>
        </div>)
}