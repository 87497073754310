import { db } from "./db.js";
import { useLiveQuery } from "dexie-react-hooks";
import { useSelector } from 'react-redux';
import { setBadges } from "./databaseSlice.js"

export function ActiveBadges(activeUser)
{
    var dbData = useLiveQuery(() => db?.badges.filter(item => activeUser?.badges?.includes(item.id)).toArray(), [activeUser])
    var stateData = useSelector((state) => state.database.badges?.filter(item => activeUser?.badges?.includes(item.id)));
    if (db) return dbData;
    return stateData;
}

export function InActiveBadges(activeUser)
{
    var dbData =  useLiveQuery(() => db?.badges.filter(item => !(activeUser?.badges?.includes(item.id)) && item.bookIds?.length > 0).toArray(), [activeUser]);
    var stateData = useSelector((state) => state.database.badges?.filter(item => !(activeUser?.badges?.includes(item.id)) && item.bookIds?.length > 0));
    if (db) return dbData;
    return stateData;
}

export async function SaveBadges(dispatch, list) 
{
    dispatch(setBadges(list));
    if (db) await db.badges.bulkPut(list);
}