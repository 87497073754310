import { useState } from "react"
import styles from "./LanguageSelector.module.css"
import check from "../images/check.svg"
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../features/language/language.js"
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import TranslationText from "./TranslationText";

export default function LanguageSelector() {
    const language = useSelector((state) => state.language.language);
    const navigate = useNavigate();
    const [selected, SetSelected] = useState("en");
    useEffect(() => {
        SetSelected(language);
    }, [language])

    const dispatch = useDispatch();
    const OnSave = () => {
        dispatch(setLanguage(selected));
        navigate('/', {replace: false});
    }

    return (<div className={styles.Main}>
        <div className={styles.Title}><TranslationText value="select_language_text"/></div>
        <div className={styles.List}>
            <div className={styles.Language + (selected === "en" ? " " + styles.Selected : "")} onClick={() => SetSelected("en")}>
                English
                <img src={check} alt="check" className={styles.Check} hidden={selected !== "en"}/>
            </div>
            <div className={styles.Language + (selected === "id"  ? " " + styles.Selected : "")} onClick={() => SetSelected("id")}>
                Bahasa Indonesia
                <img src={check} alt="check" className={styles.Check} hidden={selected !== "id"}/>
            </div>
            <div className={styles.Language + (selected === "es"  ? " " + styles.Selected : "")} onClick={() => SetSelected("es")}>
                Español
                <img src={check} alt="check" className={styles.Check} hidden={selected !== "es"}/>
            </div>
            <div className={styles.Button} onClick={OnSave}>
                <TranslationText value="save_changes" />
            </div>
        </div>
    </div>)
}