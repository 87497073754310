import styles from "./HomeTabDropdown.module.css";
import all from "../images/all.svg";
import allSelected from "../images/all-selected.svg";
import cont from "../images/continue.svg";
import contSelected from "../images/continue-selected.svg";
import favourites from "../images/favourites.svg";
import favouritesSelected from "../images/favourites-selected.svg";
import interests from "../images/interests.svg";
import interestsSelected from "../images/interests-selected.svg";
import myBooks from "../images/my-books.svg";
import myBooksSelected from "../images/my-books-selected.svg";
import TranslationText from "./TranslationText";
import { useState } from "react";

export default function HomeTabDropdown({ OnClick, homeTab }) {
    const iconsSelected = [allSelected, myBooksSelected, interestsSelected, favouritesSelected, contSelected];
    const icons = [all, myBooks, interests, favourites, cont];
    const texts = ["all", "my_books", "topic_interests", "favourites", "continue"];
    var [isOpen, SetOpen] = useState(false);

    return (
        <div className={styles.Main} onClick={() => SetOpen(!isOpen)}>
            <img src={icons[homeTab]} alt="icon" />
            <TranslationText value={texts[homeTab]} />
            <div className={styles.List} hidden={!isOpen}>
                {texts.map((item, index) => (
                    <div className={styles.Item + " " + (index === homeTab ? styles.Selected : "")} key={item} onClick={() => OnClick(index)}>
                        <img src={index === homeTab ? iconsSelected[index] : icons[index]} alt="index" />
                        <TranslationText value={item} />
                    </div>))}
            </div>
        </div>)
}