import styles from "./Registration.module.css"
import RegistrationPanel from "../components/RegistrationPanel"
import Logo from "../images/BOOKR-Class-logo-color.svg"
import Abstract from "../images/abstract-3.svg"
import { Navigate, useNavigate } from 'react-router-dom';
import { useState } from "react";
import UserTypeSelector from "../components/UserTypeSelector";
import BackButton from "../images/left-icon.svg";
import PrivacyPolicy from "../components/PrivacyPolicy";

export default function Registration()  {
    const navigate = useNavigate();
    var [userType, SetUserType] = useState();
    var [privacy, SetPrivacyPolicy] = useState(false);
    const handleClick = () => {
        navigate('/', {replace: false});
      };
      const handleBack = () => {
        if (userType)
            SetUserType("");
        else if (privacy)
            SetPrivacyPolicy(false);
        else
            navigate('/login', {replace: false});
      }

    const hasLoggedInUser = () => localStorage.getItem('hasUser') === 'true';

    return (<div>
                { hasLoggedInUser() ? <Navigate to="/" /> :
                <div className={styles.Main}>
                    <div className={styles.Logo} onClick={handleClick}>
                        <img src={Logo} alt="Bookr Class Logo"/>
                    </div>
                    <div className={styles.backButton} onClick={handleBack}>
                        <img src={BackButton} alt="back-button"></img>
                    </div>
                    {!privacy 
                    ? <PrivacyPolicy onSubmit={() => SetPrivacyPolicy(true)} />
                    : userType 
                    ? <RegistrationPanel userType={userType}/> 
                    : <UserTypeSelector selector={SetUserType}/>}
                    <div className={styles.Abstract}>
                        <img src={Abstract} alt="Bookr Class Logo"/>
                    </div>
                </div>
                }
            </div>)
}