import styles from "./BookDetails.module.css"
import levelIcon from "../images/select-icon.svg";
import coinIcon from "../images/coin-icon.svg";
import TranslationText from "./TranslationText";
import { GetLevels } from "../features/database/terms.js"

export default function BookDetailsNumbers({ book }) {

    const levels = GetLevels();
    var level = 0;
    if (levels)
        for(var item of levels)
        {
            if (book.terms.includes(item.id))
            {
                level = item.title;
            }
        }

    return (<div className={styles.numbers}>
        { 
        book?.lexileScore > 0 
            ? <div>L{book.lexileScore}</div> 
            : null
        }
        <div>
            <img src={levelIcon} alt="levelIcon" className={styles.levelIcon}/>
            <TranslationText value="level_text"/>
            {" " + level}
        </div>
        <div>
            <img src={coinIcon} alt="levelIcon" className={styles.coinIcon}/>
            {book.experiencePoints} 
        </div>
    </div>)
}