import styles from './BookRewards.module.css';
import coinIconMobile from '../../images/coin-mobile.svg';
import TranslationText from '../../components/TranslationText';
import { useDispatch } from 'react-redux';
import { closeWindow } from '../../features/windows/windowSlice';
import { useNavigate } from 'react-router-dom';
import SimilarBooks from '../../components/SimilarBooks';
import { Translate } from "../../features/database/uiTexts.js";

export default function BookRewards({ book, XP, badgeTitle, badgeURL, badgeRequired, dailyRewardMode }) {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const onGoToLibrary = () => {
        dispatch(closeWindow());
        navigate("/");
        window.location.reload();
    }

    let dailyReadReward = dailyRewardMode || false;

    let showBadge = badgeRequired || false;

    /* Maybe this should be fine tuned */
    const isMobile = window.matchMedia('(max-width: 1199px)').matches;

    let you_earned = Translate("book_rewards_earned");
    let displayed_you_earned_text = you_earned?.value;

    console.log(displayed_you_earned_text);

    if (isMobile) {
        if (displayed_you_earned_text) {
            displayed_you_earned_text = displayed_you_earned_text.replace(":", "")
            console.log("Mobile detected ok : " + displayed_you_earned_text);
        }
    }

    const isUltraSmallMobile = window.matchMedia('(max-width: 300px)').matches;

    return (<div>
            {!dailyReadReward ? 
                <div>
                    {showBadge ? 
                    <div className={`row ` + styles.Main}>
                        <div className={`col-md-4 col-12 ` + styles.youEarnedSection}>
                            {/*<h2 className={styles.rewardsHeaders}><TranslationText value="book_rewards_earned" /></h2>*/}
                            <h2 className={styles.rewardsHeaders}>{displayed_you_earned_text}</h2>
                            <br />
                            <div className={`row pt-3 text-center align-items-end`} >
                                <figure className='col-6 text-center'>
                                    <img className={styles.badgeImage} src={badgeURL} alt='Badge' />
                                    <figcaption><TranslationText value="your_badge_text" /></figcaption>
                                </figure>
                                <figure className='col-6 text-center'>
                                    <img className={styles.coinIcon} src={coinIconMobile} alt='Coin' />
                                    <figcaption>{XP + `x `}<TranslationText value="book_rewards_coins" /></figcaption>
                                </figure>
                                <div className={styles.btnGoToLibrary} onClick={onGoToLibrary}>
                                    <TranslationText value="go_to_library" />
                                </div>
                            </div>
                            <div className={styles.youEarnedHolder}></div>
                        </div>
                        <div className={`col-md-8 col-12 ` + styles.youMayAlsoLikeSection}>
                            <h2 className={styles.rewardsHeaders}><TranslationText value="you_may_also_like_these_books" /></h2>
                            <SimilarBooks book={book} displayAWStyle={true} isUltraSmallMobile={isUltraSmallMobile} />
                        </div>
                    </div>
                    :
                    <div className={`col-12 ` + styles.youMayAlsoLikeSection + ` ` + styles.clearBg}>
                        <h2 className={styles.rewardsHeaders + ` ` + styles.rewardsHeadersSmaller}><TranslationText value="you_may_also_like_these_books" /></h2>
                        <SimilarBooks book={book} displayAWStyle={true} isUltraSmallMobile={isUltraSmallMobile} />
                    </div>
                }
                </div>
                :
                /* Daily Book Read Reward - 20 mins */
                <div>
                    <div className={`col-12 ` + styles.youEarnedSection}>
                            <h2 className={styles.rewardsHeaders + ` ` + styles.dailyReadRewardHeader}>Wow, you’ve read 20 minutes today!<br/>You earned: </h2>
                            <br />
                            <div className={`row pt-3 text-center align-items-end`} >
                                <figure className='col-12 text-center'>
                                    <img className={styles.coinIcon} src={coinIconMobile} alt='Coin' />
                                    <figcaption>{10 + `x coins`}</figcaption>
                                </figure>
                                <div className={styles.btnGoToLibrary} onClick={onGoToLibrary}>
                                    Continue Reading
                                </div>
                            </div>
                            <div className={styles.youEarnedHolder + ` ` + styles.dailyReadRewardBg}></div>
                        </div>
                </div>
            }
            </div>);
}
