import Navbar from '../components/Navbar'
import LanguageSelector from "../components/LanguageSelector"
import styles from "./Language.module.css"
import abstractLeft from "../images/abstract-5.svg"
import abstractRight from "../images/abstract-6.svg"

export default function Language() {
    return(<div className={styles.Main}>
        <Navbar/>
        <LanguageSelector/>
        <img src={abstractLeft} alt="abstractLeft" className={styles.AbstractLeft}/>
        <img src={abstractRight} alt="abstractRight" className={styles.AbstractRight}/>
    </div>)
}