import leftArrow from '../images/left-icon.svg'
import styles from "./LoginCodePanel.module.css"
import { loginWithAccessCode } from "../features/users/login.js"
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TranslationText from './TranslationText.jsx';
import { openWindow } from "../features/windows/windowSlice";
import { ValidateAccessCode } from '../features/validation/validation.js'
import InputErrorMessage from "./InputErrorMessage";
import { Translate } from "../features/database/uiTexts.js";

export default function LoginPanel() {
    var [ studentCode, SetStudentCode ] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ errorMessage, SetErrorMessage ] = useState("");
    const [ errorMessageStudent, SetErrorMessageStudent ] = useState("");
    
    const input_access_code_wrong_format = Translate("input_access_code_wrong_format");
    const student_code_not_correct = Translate("student_code_not_correct");
    
    const onSubmitStudentCode = (event) => {
        // console.log(studentCode);
        if (!ValidateAccessCode(studentCode))
        {
            SetErrorMessageStudent(input_access_code_wrong_format.value);
            event.preventDefault();
            return false;
        }
        loginWithAccessCode(dispatch, studentCode, (result, message) => {
            console.log(result)
            if (result)
            {
                SetErrorMessageStudent("")
                navigate('/', {replace: false});
            } else {
                SetErrorMessageStudent(student_code_not_correct.value)
            }
        });
        event.preventDefault();
    }

    const onBackToSignIn = () => {
        navigate("/login");
    }

    const onSkip = () => {
        navigate("/");
    }

    const textLoginButton = Translate("login_submit");
    const textStudentCode = Translate("login_student_code_placeholder");

    return (
        <div className={styles.Main}>
            <div className={styles.Title}><TranslationText value="student_code_header"/></div>
            <InputErrorMessage message={errorMessageStudent} />
            <form className={styles.StudentCode}  onSubmit={onSubmitStudentCode} >
                {/* <div className={styles.Title}>Sign in with Student Code*</div> */}
                <div className={styles.CodeInput + (errorMessage ? styles.WithError : "")}>
                    <input id="studentCode" placeholder={textStudentCode?.value} onChange={(event) => {
                        SetStudentCode(event.target.value)
                        SetErrorMessageStudent("");
                    }} autoComplete="studentCode"/>
                </div>
                <input className={styles.SignIn} value={textLoginButton?.value} type="submit" />
                <div className={styles.Warning}><TranslationText value="student_code_info_text"/></div>
            </form>
            
            <button className={styles.BackToSignIn} onClick={onBackToSignIn}>
                <img src={leftArrow} alt="Back To Sign In" className={styles.ArrowBackToSignIn} />
                <TranslationText value="back_to_sign_in"/>
            </button>

            <button className={styles.BtnSkip} onClick={onSkip}>
                <TranslationText value="skip"/>
                <img src={leftArrow} alt="Back To Sign In" className={styles.RightArrow} />
            </button>
        </div>
    )
}