import styles from "./InterestsContent.module.css"
import { closeWindow } from "../../features/windows/windowSlice.js"
import { useSelector, useDispatch } from 'react-redux';
import { useState } from "react";
import Interest from "../../components/Interest"
import { saveChild } from "../../features/users/login"
import TranslationText from "../../components/TranslationText";
import { GetTopics } from "../../features/database/terms.js"

export default function InterestsContent() 
{
    const dispatch = useDispatch();
    const activeUser = useSelector((state) => state.user.activeUser);
    const accessToken = useSelector((state) => state.user.accessToken);
    const topics = GetTopics();
    const [interestedTopics, setInresstedTopics] = useState(activeUser.interestedTopics, [activeUser])
    const onSave = (event) => {
        event.preventDefault();
        dispatch(closeWindow());
        var saveableUser = { id: activeUser.id, interestedTopics: [...interestedTopics]}
        saveChild(dispatch, saveableUser, accessToken, (data) => console.log(data))
    }

    const onSelect = (id) => {
        if (!interestedTopics) return;
        if (interestedTopics.includes(id))
            setInresstedTopics(interestedTopics.filter(item => item !== id));
        else 
            setInresstedTopics([...interestedTopics, id]);
    }
        //interestedTopics
    return (
        <div>
            <div className={styles.SubTitle}><TranslationText value="topic_interests_choose_more"/></div>
            <div className={styles.List}>
                {topics ? topics.map(item => (
                <Interest 
                item={item} 
                key={item.id} 
                isSelected={interestedTopics && interestedTopics.includes(item.id)}
                onClick={() => onSelect(item.id)}
                />)
                ) : null}
            </div>
            <div className={styles.SaveChanges} onClick={onSave}>
                <TranslationText value="save_changes" />
            </div>
        </div>)
}