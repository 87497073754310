import { useEffect } from "react";
import { useDispatch } from "react-redux"
import { openWindow } from "../features/windows/windowSlice.js";
import { GetAllNotReadNotifications } from "../features/database/notifications.js"

export default function NewAssignedBooks() {
    const notifications = GetAllNotReadNotifications();

    const dispatch = useDispatch();
    useEffect(() => {
        if (!notifications || notifications.length === 0 || !dispatch) return;
        dispatch(openWindow({ type: "assignedBooks", params: { notifications }, title: "new_book_assigned", subTitle: "", Small: true }));
    }, [notifications, dispatch])

    return (<div></div>)
}