
import styles from './ProfileDashboardButton.module.css';
import Avatar from "./Avatar";
import Login from "../images/login.svg"
import { useState } from "react"
import { logOut } from "../features/users/login.js"
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { setActiveUserId } from '../features/users/userSlice.js'
import TranslationText from "./TranslationText"

export default function ProfileDashboardButton() {
    const [ isOpen, SetOpen ] = useState(false)
    const activeUser = useSelector((state) => state.user.activeUser);
    const loggedInUser = useSelector((state) => state.user.loggedInUser);
    const children = useSelector((state) => state.user.children);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleBlur = () => {
        SetOpen(false);
    }

    const handleClick = () => {
        SetOpen(!isOpen);
      };
    const handleClickLogin = () => {
        navigate('/login', {replace: false});
      };
    const handleClickLogOut = () => {
        logOut(dispatch);
        navigate('/login', {replace: false});
    };
    const handleClickProfile = () => {
        navigate('/profile', {replace: false});
    };
    const handleClickHelp = () => {
        window.open('https://bookrclass.com/help-center/','_blank');
    };
    const handleClickLanguage = () => {
        navigate('/language', {replace: false});
    };
    const onChildSelected = (id) => {
        dispatch(setActiveUserId(id))
    }
    return(
        <div tabIndex={0} className={styles.Main} onClick={handleClick} onBlur={handleBlur}>
            {activeUser ? 
            (<Avatar/>)
            : (<img src={Login} alt="login-logo"/>)}
            <div className={styles.DropDown} hidden={!isOpen}>
                <div hidden={!activeUser} onClick={handleClickProfile}><TranslationText value="my_profile"/></div>
                {children?.map(item => <div onClick={() => onChildSelected(item.id)} key={item.id} hidden={activeUser?.id === item.id}>{item.name}</div>)}
                <div onClick={() => onChildSelected(loggedInUser?.id)} hidden={!loggedInUser || activeUser?.id === loggedInUser?.id}>{loggedInUser?.name}</div>
                <div onClick={handleClickLanguage}><TranslationText value="dashboard_settings"/></div>
                <div onClick={handleClickHelp}><TranslationText value="dashboard_help"/></div>
                <div onClick={handleClickLogOut} hidden={!activeUser}><TranslationText value="log_out"/></div>
                <div onClick={handleClickLogin} hidden={activeUser}><TranslationText value="log_in"/></div>
            </div>
            
        </div>)
}