import styles from "./BookDetails.module.css";
import TranslationText from "./TranslationText";
import { useSearchParams } from 'react-router-dom';
import playIcon from "../images/play-mobile.svg"
import isMobileOrTablet from "../features/common/common.js"

export default function BookDetailsButtons({onClick, book, onDeeplinkClick}) {

    const [searchParams] = useSearchParams();

    return (<div className={styles.buttons}>
        {
        isMobileOrTablet() && book?.type === "flashcard" ? null : 
        <div className={styles.playButton} onClick={onClick}>
            <img src={playIcon} alt="levelIcon"/>
        </div>
        }
        <div className={styles.deeplink} onClick={onDeeplinkClick}>
            <TranslationText value="open_in_app"/>
        </div>
    </div>);
}