import { useEffect } from "react";
import { useState } from "react"
import styles from "./HelpList.module.css"
import HelpBlock from "./HelpBlock"
import TranslationText from "./TranslationText";
import { useSelector } from "react-redux";

export default function HelpList() {

    var [helpList, setHelpList] = useState([]);
    var [lastLanguage, setLastLanguage] = useState("en");
    const language = useSelector((state) => state.language.language);

    useEffect(() => {
        if (!helpList || helpList.length === 0 || language !== lastLanguage) {
            setLastLanguage(language);
            fetch('https://api.v2.bookrclass.com/api/mobile/faq?locale=' + language).then(res => res.json())
            .then(data => {
                const groupByCategory = data.result.list.reduce((group, product) => {
                    const { category } = product;
                    group[category] = group[category] ?? [];
                    group[category].push(product);
                    return group;
                }, {});
                setHelpList(groupByCategory);
            })
            .catch(exception => console.log(exception))
        }

    }, [helpList, language, lastLanguage])    
    const[ selectedIndex, SetIndex ] = useState(-1);

    const OnClick = (index) => {
        if (index === selectedIndex)
            SetIndex(-1);
        else
            SetIndex(index);
    }

    return (
    <div className={styles.Main}>
        <div className={styles.Title}><TranslationText value="dashboard_help_header"/></div>
        {helpList ? Object.keys(helpList)
        .map((item, index) => (<HelpBlock key={index} category={item} questions={helpList[item]} 
        isOpen={selectedIndex === index} OnClick={() => OnClick(index)}/>
        )) : null}
    </div>)
}