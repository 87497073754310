import {
  createSlice
} from '@reduxjs/toolkit'

export const windowSlice = createSlice({
  name: 'window',
  initialState: {
    data: undefined,
  },
  reducers: {
    openWindow: (state, action) => {
      state.data = action.payload;
    },
    closeWindow: (state) => {
      state.data = undefined;
    },

  }
})

// Action creators are generated for each case reducer function
export const {
  openWindow,
  closeWindow
} = windowSlice.actions

export default windowSlice.reducer