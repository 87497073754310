import { db } from "./db.js";
import { useLiveQuery } from "dexie-react-hooks";
import { setTerms, setLevels } from "./databaseSlice.js"
import { useSelector } from 'react-redux';
import store from "../../store.js";

export function GetAllTerms()
{
    if (db)
        return db?.terms.toArray();
    var state = store.getState();
    return state.database.terms;
}

export function GetLevels()
{
    var dbData = useLiveQuery(() => db?.terms.where("taxonomyId").equals(8).toArray());
    var stateData = useSelector(state => state.database.terms.filter(item => item.taxonomyId === 8));
    return db ? dbData : stateData;
}

export function GetLevel(levelId)
{
    var dbData = useLiveQuery(() =>  db?.levels.get(levelId), [levelId]);
    var stateData = useSelector(state => state.database.levels.filter(item => item.id === levelId));
    return db ? dbData : stateData[0];
}

export function GetTopics()
{
    var dbData = useLiveQuery(() => db?.terms.where("taxonomyId").equals(1).toArray());
    var stateData = useSelector(state => state.database.terms.filter(item => item.taxonomyId === 1));
    return db ? dbData : stateData;
}

export function GetIntrests(activeUser)
{
    var dbData = useLiveQuery(() => db?.terms.where("taxonomyId").equals(1)
                .filter(item => activeUser && activeUser.interestedTopics?.includes(item.id)).toArray(), [activeUser]);
    var stateData = useSelector(state => state.database.terms
        .filter(item => item.taxonomyId === 1 && activeUser && activeUser.interestedTopics?.includes(item.id)));
    return db ? dbData : stateData;
}

export async function SaveTerms(dispatch, list)
{
    dispatch(setTerms(list));
    if (db) await db.terms.bulkPut(list);
}

export async function SaveLevels(dispatch, levels)
{
    dispatch(setLevels(levels));
    if (db) await db.levels.bulkPut(levels);
}

export function GetTermByTitle(topic)
{
    if (db) return db.terms.where("title").equalsIgnoreCase(topic?.toString() ?? "").first();
    var state = store.getState();
    return state.database.terms.filter(item => item.title === topic?.toString() ?? "")[0];
}