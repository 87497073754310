import { createSlice } from '@reduxjs/toolkit'
import { UpdateUser } from "../database/login.js"

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    loggedInUser: null,
    accessToken: null,
    children: null,
    activeUser: null,
  },
  reducers: {
    setActiveUserId: (state, action) => {
      const userId = Number.parseInt(action.payload);
      var user = null;
      if (state.loggedInUser.id === userId) user = state.loggedInUser;
      for (var index in state.children)
      {
        if (state.children[index].id === userId)
        user = state.children[index];
      }
      state.activeUser = user ?? state.loggedInUser;
      
      UpdateUser(userId);
    },

    setUser: (state, action) => {
      state.loggedInUser = action.payload
      state.activeUser = action.payload
    },

    setUserAfterSave: (state, action) => {
      const user = action.payload
      if (state.loggedInUser.id === user.id) state.loggedInUser = user;
      if (state.activeUser.id === user.id) state.activeUser = user;
      for (var index in state.children)
      {
        if (state.children[index].id === user.id)
          state.children[index] = user;
      }
    },

    setChildren: (state, action) => {
      state.children = action.payload
    },

    setAccessToken: (state, action) => {
      state.accessToken = action.payload
    },

  }
})

// Action creators are generated for each case reducer function
export const { setUser, setAccessToken, setChildren, setUserAfterSave, setActiveUserId } = userSlice.actions

export default userSlice.reducer