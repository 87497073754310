/* eslint-disable no-invalid-regexp */
/* eslint-disable no-control-regex */
export function ValidateEmail(value) {
    var regex = new RegExp("(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])");
    return regex.test(value);
}

export function ValidateUsername(value) {
    if (value.includes("\"")) return false;
    var regex = new RegExp("^[a-zA-Z0-9._-]{6,}$");
    return regex.test(value);
}

export function ValidateName(value) {
    if (value.includes("\"")) return false;
    var regex = new RegExp("^[a-zA-Z0-9À-ÿűőŰŐ' ]{2,}$");
    return regex.test(value);
}

export function ValidatePassword(value) {
    if (value.includes("\"") || value.includes("/")) return false;
    var regex = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-ZÀ-ÿűőŰŐ0-9.!@$&:,?-_;%=+]{6,}$");
    return regex.test(value);
}

export function ValidateAge(value) {
    try {
        var age = Number.parseInt(value);
        return age >= 1 && age <= 18;
    } catch (exp)
    {
        console.warn(exp);
        return false;
    }
}

export function ValidateBirthYear(value) {
    try {
        var age = Number.parseInt(value);
        return age >= 2000 && age <= new Date().getFullYear();
    } catch (exp)
    {
        console.warn(exp);
        return false;
    }
}

export function ValidateSchool(value)
{
    return !value.includes("\"") && value.length >= 6;
}

export function ValidateAccessCode(value)
{
    return !value.includes("\"") && value.length >= 6;
}

export function ValidateEmailOrUsername(value)
{
    return ValidateEmail(value) || ValidateUsername(value);
}