import Navbar from '../components/Navbar'
import HelpList from '../components/HelpList'
import Methodology from '../components/Methodology'
import Contacts from '../components/Contacts'
import styles from "./Help.module.css"
import abstract from "../images/abstract-4.svg"

export default function Help() {
    return (
    <div className={styles.Main}>
        <Navbar/>
        <div className={styles.Grid}>
            <HelpList />
            <Methodology />
            <Contacts />
        </div>
        <img src={abstract} alt="abstract" className={styles.Abstract}/>
    </div>)
}