import styles from "./InputErrorMessage.module.css";
import warningImage from "../images/warning.svg";

export default function InputErrorMessage({message}) {
    return (<div className={styles.WarningMessage} hidden={!message}>
        <div className={styles.warningLogo}>
            <img src={warningImage} alt="warning"/>
        </div>
        <div>{message}</div>
    </div>)
}