import styles from "./Badges.module.css"
import { useSelector } from 'react-redux';
import Badge from "./Badge"
import TranslationText from "./TranslationText"
import { ActiveBadges, InActiveBadges } from "../features/database/badges.js"

export default function Badges() 
{
    const activeUser = useSelector((state) => state.user.activeUser);
    const badgesActive = ActiveBadges(activeUser);
    const badgesInActive = InActiveBadges(activeUser);
    //badges
    return (<div className={styles.Main} hidden={!activeUser || activeUser.type !== "child"}>
        <div className={styles.Title}><TranslationText value="dashboard_badges"/></div>
        <div className={styles.List}>
            {badgesActive ? 
        badgesActive.map(item => (<Badge image={item.iconUrls?.original_xl} isActive={true} key={item.id}/>)) 
        : null}
         {badgesInActive ? 
        badgesInActive.map(item => (<Badge image={item.iconUrls?.original_xl} isActive={false} key={item.id}/>)) 
        : null}
        </div>
    </div>)
}