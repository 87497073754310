import styles from "./Interests.module.css"
import { openWindow } from "../features/windows/windowSlice.js";
import { useDispatch, useSelector } from 'react-redux';
import Interest from "./Interest"
import editButton from "../images/edit.svg"
import TranslationText from "./TranslationText";
import { GetIntrests } from "../features/database/terms.js"

export default function Interests() {
    const activeUser = useSelector((state) => state.user.activeUser);
    const dispatch = useDispatch();
    const topics = GetIntrests(activeUser)
    const OpenEdit = () => {
        dispatch(openWindow({ title: "topic_interests", type: "interests" }))
    }
    return (<div className={styles.Main} hidden={!activeUser || activeUser.type !== "child"}>
        <div className={styles.Title}>
            <TranslationText value="topic_interests" />
            <div className={styles.Edit} onClick={OpenEdit}>
                <img src={editButton} alt="edit" />
            </div></div>
        <div className={styles.List}>
            {topics ? topics.map(item => (<Interest item={item} key={item.id} />)) : null}
        </div>
    </div>)
}