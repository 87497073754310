
import styles from "./IFrameWrapper.module.css"
import { close }from "../features/webgl/webgl.js"
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import leftIcon from "../images/left-icon.svg"
import { closeWindow } from "../features/windows/windowSlice";

export default function IFrameWrapper({book, bookId}) {
    const dispatch = useDispatch();
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (dispatch)
            document.addEventListener("keydown", (event) => {
                if(event.key === 'Escape') {
                    dispatch(close())
                }
            }, false);
    }, [dispatch])

    const ref1 = useRef(null);
    
    const activeUser = useSelector((state) => state.user.activeUser);
    const accessToken = useSelector((state) => state.user.accessToken);
    const isOpen = useSelector((state) => state.webgl.open);
    
    const isMobile = () => window.matchMedia('(max-width: 1199px)').matches;
    if (accessToken && !searchParams.has("accessToken"))
        searchParams.append("accessToken", accessToken);
    if (activeUser && !searchParams.has("activeUserId"))
        searchParams.append("activeUserId", activeUser.id);

    const iframe = process.env.REACT_APP_IFRAME + "?" + searchParams.toString();
    const deeplinkUrl = iframe;

    /* Closing the IFrame in case of a Subscription Gate received and user clicked on one of its buttons e.g. Login/Subscribe/Close */
    window.addEventListener("message", (event) => {
        /* Close IFrame */
        if (event.data === "InternalCmd_BKR_WebGL_Book_Player_Close_Me_Now") {
            console.log("Internal Close Command Received");
            dispatch(close());
        }

        /* Open Login and Close IFrame */
        if (event.data === "InternalCmd_BKR_WebGL_Book_Player_Open_Login_and_Close") {
            console.log("Internal Login and Close Command Received");
            dispatch(closeWindow({type: "bookRewards"}));
            navigate("/login");
        }

        /* Open Shop and Close IFrame */
        if (event.data === "InternalCmd_BKR_WebGL_Book_Player_Open_Shop_and_Close") {
            console.log("Internal Shop and Close Command Received");
            window.open('https://bookrclass.com/shop/','_blank');
            dispatch(close());
        }
    })
    
    useEffect(() => {
        if (ref1?.current?.contentWindow)
        {
            try {
                console.log(ref1.current.contentWindow.origin);
                //If you have access to the contentWindow.origin, then it is not loaded correctly
            } catch(_) 
            {
                if (!isOpen && isMobile())
                    ref1.current.contentWindow.postMessage("pauseBook", deeplinkUrl);
                //else
                //    ref1.current.contentWindow.postMessage("startBook", deeplinkUrl);
            }
        }
    }, [isOpen, ref1, deeplinkUrl])
    return (<div className={styles.player} hidden={!isOpen} onClick={() => dispatch(close())} >
        {
            book.type == "flashcard" ?
                <iframe title="WebGLBookView" src={deeplinkUrl} ref={ref1} loading="lazy"
                         allow="microphone https://webgl.bookrclass.com/;" />
            :
                <iframe title="WebGLBookView" src={deeplinkUrl} ref={ref1} loading="lazy" />
        }
        <img src={leftIcon} alt="backIcon"  onClick={() => dispatch(close())} className={styles.lefticon} />
    </div>);
}