import { setAccessToken, setUser, setUserAfterSave, setChildren, setActiveUserId } from "./userSlice.js"
import md5 from 'md5-hash';
import { fetchBookUserData, fetchBlackList } from "../Statistics/statisctics.js"
import { SaveLogin, DeleteLogin } from "../database/login.js"

export function loginWithToken({ token, ssoId, returnUrl }) {
    //call endpoint
    var url = process.env.REACT_APP_APIBASE +"oauth/sso/app/login/" + ssoId + "?token=" + token + "&returnUrl=" + returnUrl;
    //var url = process.env.REACT_APP_APIBASE +"oauth/sso/app/login/" + ssoId + "?token=" + token + "&platform=web";
    window.location.replace(url);
  }

export function loginWithCode({ code, ssoId, returnUrl }) {
    var url = process.env.REACT_APP_APIBASE +"oauth/sso/app/login/" + ssoId + "?code=" + code + "&returnUrl=" + returnUrl;
    window.location.replace(url);
  }

export function loginWithAccessToken(dispatch, accessToken) {
    dispatch(setAccessToken(accessToken));

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + accessToken);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_APIBASE + "mobile/users/me", requestOptions)
      .then(response => response.json())
      .then(async data => {
        await parseUserDataResult(dispatch, data, accessToken, null);
      })
      .catch(error => console.log('error', error));

  }

export function loginWithServerToken(dispatch, { token, ssoId }) {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "token": token,
      "sso_id": ssoId,
      "client_id": "2",
      "client_secret": "BookrAWOauthClientDummySecret4Mobile0000"
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_APIBASE + "oauth/token/sso", requestOptions)
      .then(response => response.json())
      .then(async data => 
        {
          await parseUserData(dispatch, data, null, null);
          await fetchBookUserData(dispatch, data.access_token, data.user.id);
          await fetchBlackList(dispatch, data.access_token, data.user.id);
        })
      .catch(error => console.log('error', error));
    }

function convertPassword(string) {
  return md5(string);
  //return await hash(string);
}

//// --------- PARSE FOUND DATA

async function parseUserData(dispatch, data, onReturned, activeUserId) {
  try {
    await SaveLogin({
      access_token: data.access_token, 
      refresh_token: data.refresh_token, 
      user: data.user,
      activeUserId: activeUserId ?? data.user.id
    })
    dispatch(setAccessToken(data.access_token));
    dispatch(setUser(data.user));
    dispatch(setActiveUserId(activeUserId));
  
    if (data.user.type === "child")
    {
      await fetchBookUserData(dispatch, data.access_token, data.user.id);
      await fetchBlackList(dispatch, data.access_token, data.user.id);
      onReturned(true, "Logged In");
    }
    else if (onReturned)
      onReturned(true, "Logged In");  
  } catch (excpetion)
  {
    if (onReturned)
      onReturned(false, excpetion);
  }
}


async function parseUserDataResult(dispatch, data, access_token, onReturned) {
  try {
    await SaveLogin({
      access_token: access_token, 
      refresh_token: null, 
      user: data.result,
      activeUserId: data.result.id
    })
    dispatch(setAccessToken(null));
    dispatch(setUser(data.result));
    dispatch(setActiveUserId(data.result.id));
  
    if (data.user.type === "child")
    {
      await fetchBookUserData(dispatch, access_token, data.user.id);
      await fetchBlackList(dispatch, data.access_token, data.user.id);
    }
    if (onReturned)
      onReturned(true, "Logged In");
  } catch (excpetion)
  {
    if (onReturned)
      onReturned(false, excpetion);
  }
}

/// -------- PARSE FOUND DATA END

export async function logOut(dispatch) {
  localStorage.removeItem('hasUser');
  await DeleteLogin();
  dispatch(setAccessToken(null));
  dispatch(setUser(null));
  dispatch(setChildren([]));
}

async function parseUserDataAfterSave(dispatch, response, onReturned) {
  try { 
    if (!response.ok)
      throw await response.json();
    const data = await response.json();
  
   
    dispatch(setUserAfterSave(data.result));
  
    if (onReturned)
      onReturned(true, data.result.subscription);
  } catch (excpetion)
  {
    if (onReturned)
      onReturned(false, excpetion);
  }
 
}

async function parseChildrenDataAfterSave(dispatch, accessToken, response) {
  if (!response.ok)
    throw await response.json();
  const data = await response.json();

  for (var child of data.result.list)
  {
    await fetchBookUserData(dispatch, accessToken, child.id);
    await fetchBlackList(dispatch, accessToken, child.id);
  }
   
  dispatch(setChildren(data.result.list));
}

async function fetchChildren(dispatch, accessToken, onReturned) {
  try {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", "Bearer " + accessToken);
  
  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  
  const response = await fetch(process.env.REACT_APP_APIBASE + "mobile/children", requestOptions);
  
  await parseChildrenDataAfterSave(dispatch, accessToken, response);
  } catch(error) {
      console.log('error', error);
      if (onReturned)
        onReturned(false, error.message);
  };
}

export async function refreshMe(dispatch, accessToken, onReturned) {
  try {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", "Bearer " + accessToken);
  
  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  
  const response = await fetch(process.env.REACT_APP_APIBASE + "mobile/users/me", requestOptions);
  await fetchChildren(dispatch, accessToken);
  await parseUserDataAfterSave(dispatch, response, onReturned);
  } catch(error) {
      console.log('error', error);
      if (onReturned)
        onReturned(false, error.message);
  };
}

export async function saveChild(dispatch, userData, accessToken, onReturned) {
  try {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", "Bearer " + accessToken);
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify(userData);
  
  var requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  const response = await fetch(process.env.REACT_APP_APIBASE + "mobile/child/" + userData.id, requestOptions);
  await parseUserDataAfterSave(dispatch, response, onReturned);
  } catch(error) {
      console.log('error', error);
      if (onReturned)
        onReturned(false, error.message);
  };
}

export async function saveMe(dispatch, userData, accessToken, onReturned) {
  try {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", "Bearer " + accessToken);
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify(userData);
  
  var requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  const response = await fetch(process.env.REACT_APP_APIBASE + "mobile/users/me", requestOptions);
  await parseUserDataAfterSave(dispatch, response, onReturned);
  } catch(error) {
      console.log('error', error);
      if (onReturned)
        onReturned(false, error.message);
  };
}

export async function refreshToken(dispatch, refresh_token, onReturned, activeUserId) {
  try {
    console.log(activeUserId);
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({
    "refresh_token": refresh_token,
    "client_id": "2",
    "client_secret": "BookrAWOauthClientDummySecret4Mobile0000"
  });
  
  var requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  const response = await fetch(process.env.REACT_APP_APIBASE + "oauth/token", requestOptions);
  if (!response.ok)
    throw await response.json();

  console.log("refresh token success");
  const data = await response.json();
  await fetchChildren(dispatch, data.access_token);
  await parseUserData(dispatch, data, onReturned, activeUserId);

  } catch(error) {
      console.log('refresh token error');
      console.log('error', error);
      logOut(dispatch);
      if (onReturned)
        onReturned(false, error.message);
  };
}

export async function loginWithAccessCode(dispatch, accessCode, onReturned) {
  try {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({
    "accessCode": accessCode,
    "client_id": "2",
    "client_secret": "BookrAWOauthClientDummySecret4Mobile0000"
  });
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  const response = await fetch(process.env.REACT_APP_APIBASE + "oauth/token/accessCode", requestOptions);
  if (!response.ok)
    throw await response.json();
  const data = await response.json();
  await fetchChildren(dispatch, data.access_token);
  await parseUserData(dispatch, data, onReturned);  
  } catch(error) {
      console.log('error', error);
      if (onReturned)
        onReturned(false, error.message);
  };
}

export async function registerUser(dispatch, { name, userName, password, school, userType, newsLetter }, onReturned) {
  try {
    
  const converted = convertPassword(password);
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");

  var raw = null;
  
  if (userType.toLowerCase() !== "teacher") {
    raw = JSON.stringify({
      username: userName,
      password: converted,
      name: name,
      "client_id": "2",
      "client_secret": "BookrAWOauthClientDummySecret4Mobile0000",
      consentMarketing : true,
      consentChildStats: true,
      consentNewsletter: newsLetter,
      type: userType
    });
  } else {
    raw = JSON.stringify({
      username: userName,
      password: converted,
      name: name,
      schoolName: school,
      "client_id": "2",
      "client_secret": "BookrAWOauthClientDummySecret4Mobile0000",
      consentMarketing : true,
      consentChildStats: true,
      consentNewsletter: newsLetter,
      type: userType
    });
  }

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  const response = await fetch(process.env.REACT_APP_APIBASE + "mobile/users/me", requestOptions);
  if (!response.ok)
    throw await response.json();

  await loginWithUsernameAndPassword(dispatch, { userName, password }, onReturned);
  } catch(error) {
      console.log('error', error);
      if (onReturned)
        onReturned(false, error.message);
  };
}

export async function loginWithUsernameAndPassword(dispatch, { userName, password }, onReturned) {
  try {
    
  const converted = convertPassword(password);
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({
    "username": userName,
    "password": converted,
    "client_id": "2",
    "client_secret": "BookrAWOauthClientDummySecret4Mobile0000"
  });
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  const response = await fetch(process.env.REACT_APP_APIBASE + "oauth/token/password", requestOptions);
  if (!response.ok)
    throw await response.json();
  const data = await response.json();
  await fetchChildren(dispatch, data.access_token);
  await parseUserData(dispatch, data, onReturned);
  } catch(error) {
      console.log('error', error);
      if (onReturned)
        onReturned(false, error.message);
  };
}
  