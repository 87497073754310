import { GetAllTerms, SaveLevels } from "./terms.js"
import { GetAllBooks, SaveBooks } from "./booksTable.js"

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export async function updateLevelTermConnection(dispatch) {
    console.log("updateLevelTermConnection started");
    var books = await GetAllBooks();
    var terms = await GetAllTerms();
    if (books && terms) {
        var levels = [];
        for (const term of terms) {
            if (term.taxonomyId === 8)
            {
                var list = [];
                for (const book of books.filter(item => item.terms.includes(term.id)))
                {
                    list = list.concat(book.terms);
                }
                levels.push({id: term.id, terms: list.filter(onlyUnique) });
            }
        }
        await SaveLevels(dispatch, levels);
    } else {
        console.log("no books or terms found");
    }
    console.log("updateLevelTermConnection ended");
}

export async function updateBookSearchString(dispatch) {
    console.log("updateLevelTermConnection started");
    var books = await GetAllBooks();
    var terms = await GetAllTerms();
    if (books && terms) {
        var searchStrings = [];
        for (const book of books)
        {
            book.search = book.title + book.description;
            for (const term of terms.filter(item => book.terms.includes(item.id)))
                book.search += term.title;
            searchStrings.push(book);
        }
        await SaveBooks(dispatch, searchStrings);
    } else {
        console.log("no books or terms found");
    }
    console.log("updateLevelTermConnection ended");
}