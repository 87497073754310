import styles from "./RegistrationPanel.module.css"
import { registerUser } from "../features/users/login.js"
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TranslationText from './TranslationText.jsx';
import { ValidatePassword, ValidateEmailOrUsername, ValidateName, ValidateSchool } from '../features/validation/validation.js'
import InputErrorMessage from "./InputErrorMessage"
import { Translate } from "../features/database/uiTexts.js";

export default function RegistrationPanel({userType}) {
    var [ name, SetName ] = useState("");
    var [ school, SetSchool ] = useState("");
    var [ userName, SetUserName ] = useState("");
    var [ password, SetPassword ] = useState("");
    var [ newsLetter, SetNewsLetter ] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ errorMessage, SetErrorMessage ] = useState("");
    const [ errorMessageName, SetErrorMessageName ] = useState("");
    const [ errorMessageUserName, SetErrorMessageUserName ] = useState("");
    const [ errorMessagePassowrd, SetErrorMessagePassowrd ] = useState("");
    const [ errorMessageSchool, SetErrorMessageSchool ] = useState("");

    const input_email_wrong_format = Translate("input_email_wrong_format");
    const input_name_wrong_format = Translate("input_name_wrong_format");
    const input_school_wrong_format = Translate("input_school_wrong_format");
    const input_password_wrong_format = Translate("input_password_wrong_format");
    const login_error = Translate("registration_already_have_account");

    const handleChange = (state) => {
        SetNewsLetter(state.target.checked);
    }
    
    const onSubmitRegister = (event) => {
        console.log(name);
        if (!ValidateName(name))
        {
            SetErrorMessageName(input_name_wrong_format.value);
            event.preventDefault();
            return false;
        }
        console.log(userName);
        if (!ValidateEmailOrUsername(userName))
        {
            SetErrorMessageUserName(input_email_wrong_format.value);
            event.preventDefault();
            return false;
        }
        console.log(password);
        if (!ValidatePassword(password))
        {
            SetErrorMessagePassowrd(input_password_wrong_format.value);
            event.preventDefault();
            return false;
        }
        console.log(school);
        if (!ValidateSchool(school) && userType === "teacher")
        {
            SetErrorMessageSchool(input_school_wrong_format.value);
            event.preventDefault();
            return false;
        }
        registerUser(dispatch, { name, userName, password, school, userType, newsLetter }, (result, message) => {
            console.log(result)
            if (result)
            {
                SetErrorMessage("")
                navigate('/', {replace: false});
            } else {
                SetErrorMessage(login_error.value)
            }
        });
        event.preventDefault();
    }

    const textName = Translate("registration_name_input_required");
    const textSchool = Translate("registration_school_input_required");
    const textUserName = Translate("registration_email_input_required");
    const textPassword = Translate("registration_password_input_required");
    const textLoginButton = Translate("create_account");

    return (
        <div className={styles.Main}>
            <div className={styles.Title}><TranslationText value="registration_header"/></div>
            <div>
                <form className={styles.DefaultLogin} onSubmit={onSubmitRegister}>
                    <InputErrorMessage message={errorMessage} />
                    <InputErrorMessage message={errorMessageName} />
                    <div className={errorMessage || errorMessageName ? styles.WithError : ""}>
                        <input id="name" name="name" placeholder={textName?.value} onChange={(event) => {
                            SetName(event.target.value);
                            SetErrorMessage("");
                            SetErrorMessageName("");
                        }} autoComplete="name"/>
                    </div>
                    <InputErrorMessage message={errorMessageUserName} />
                    <div className={errorMessage || errorMessageUserName ? styles.WithError : ""}>
                        <input id="username" name="username" placeholder={textUserName?.value} onChange={(event) => {
                            SetUserName(event.target.value);
                            SetErrorMessage("");
                            SetErrorMessageUserName("");
                        }} autoComplete="username"/>
                    </div>
                    <InputErrorMessage message={errorMessagePassowrd} />
                    <div className={errorMessage || errorMessagePassowrd ? styles.WithError : ""}>
                        <input id="password" name="password" placeholder={textPassword?.value} type="password" onChange={(event) => {
                            SetPassword(event.target.value)
                            SetErrorMessage("");
                            SetErrorMessagePassowrd("");
                        }} autoComplete="current-password"/>
                    </div>
                    <InputErrorMessage message={errorMessageSchool} hidden={userType !== "Teacher"}/>
                    <div className={errorMessage || errorMessageSchool ? styles.WithError : ""} hidden={userType !== "Teacher"}>
                        <input id="school" name="school" placeholder={textSchool?.value} onChange={(event) => {
                            SetSchool(event.target.value);
                            SetErrorMessage("");
                            SetErrorMessageSchool("");
                        }} autoComplete="school"/>
                    </div>   
                    <div className={styles.Check}>
                        <input type="checkbox" id="privacy_accept2" name="privacy_accept2" onChange={handleChange}/>
                        <label for="privacy_accept2"  className={styles.Text}>
                                <TranslationText value="newsletter_accept" />
                            </label>
                    </div>
                    <div className={styles.FormSubmit}>
                        <input className={styles.SignIn} type="submit" value={textLoginButton?.value}/>
                    </div>
                </form>
            </div>
        </div>
    )
}