import TranslationText from "./TranslationText";
import styles from "./NoBookFound.module.css"
import bunny from "../images/bunny-oops.svg"

export default function NoBookFound({ additional }) {
    return (<div className={styles.Main}>
        <div className={styles.Bunny} hidden={additional}>
            <img src={bunny} alt="bunny" />
        </div>
        <div className={styles.Title} >
            <TranslationText value={additional ?? "no_books_found_text"} />
        </div>
        <div className={styles.SubTitle} hidden={additional}>
            <TranslationText value="try_another_search" />
        </div>
    </div>)
}