import { Routes , Route } from 'react-router-dom';
import Error from './pages/Error'
import Home from './pages/Home'
import Search from './pages/Search'
import Login from './pages/Login'
import LoginCode from './pages/LoginCode'
import Profile from './pages/Profile'
import Loading from './components/Loading'
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginWithAccessToken, loginWithCode, loginWithServerToken, loginWithToken } from './features/users/login.js';
import Help from './pages/Help';
import Language from './pages/Language';
import Registration from './pages/Registration';
import Window from './windows/Window';
import ResetPassword from './pages/ResetPassword';
import LoadingUserData from './components/LoadingUserData';

function App() {

  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  useEffect(() => {
      if (!dispatch || !searchParams) return;
      const accessToken = searchParams.get("accessToken");
      if (accessToken)
      {
        loginWithAccessToken(dispatch, accessToken);
        return;
      }
      const ssoId = searchParams.get("ssoId");
      if (ssoId)
      {
        const token = searchParams.get("token");
        const clientToken = searchParams.get("clientToken");
        const clientCode = searchParams.get("clientCode");
        const returnUrlRaw = searchParams.get("returnUrl");

        const isStaging = window.location.href.includes("app-staging.");

        let returnUrl = !isStaging ? "https://www.bookrclass.com/?platform=web" : "https://www.bookrclass.com/?platform=webstg";

        if (returnUrlRaw != null || returnUrlRaw != "") {
          if (returnUrlRaw.includes("/?")){
            returnUrl = returnUrlRaw + "&platform=web";
          } else if (returnUrlRaw.substring(returnUrlRaw.length - 1) == "/") {
            returnUrl = returnUrlRaw + "?platform=web";
          } else {
            returnUrl = returnUrlRaw + "/?platform=web";
          }
        }

        if (token)
        {
          loginWithServerToken(dispatch, { token: token, ssoId: ssoId });
          console.log("loginWithServerToken CALLED!");          
        } else if (clientToken)
        {
          loginWithToken({ token: clientToken, ssoId: ssoId, returnUrl: returnUrl });
        } else if (clientCode) {
          loginWithCode({ code: clientCode, ssoId: ssoId, returnUrl: returnUrl });
        }
      }
  }, [searchParams, dispatch]);

  return (
    <div>
      <LoadingUserData />
      <Loading/>
      <Routes >
        <Route exact path="/" element={<Home/>}  />
        <Route exact path="/search" element={<Search/>}  />
        <Route exact path="/login" element={<Login/>}  />
        <Route exact path="/code-sign-in" element={<LoginCode/>}  />
        <Route exact path="/reset-password" element={<ResetPassword/>}  />
        <Route exact path="/profile" element={<Profile/>} />
        <Route exact path="/help" element={<Help/>} />
        <Route exact path="/language" element={<Language/>} />
        <Route exact path="/register" element={<Registration/>} />
        <Route element={<Error/>} />
      </Routes >
      <Window />
    </div>
  );
}

export default App;
