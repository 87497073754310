import TranslationText from "../../components/TranslationText"
import styles from "./ParentalGate.module.css"
import { useState } from 'react';
import { useDispatch } from "react-redux";
import { closeWindow } from "../../features/windows/windowSlice.js"
import { useNavigate } from 'react-router-dom';

export default function ParentalGate({targetPage}) {
    
    const navigate = useNavigate();
    const [answer, setAnswer] = useState('');
    const [lNumber1, setLNumber1] = useState(Math.floor(Math.random() * 45) + 5);
    const [lNumber2, setLNumber2] = useState(Math.floor(Math.random() * 46) + 5);
    const dispatch = useDispatch();


    const onSubmitClicked = () => {
        console.log(targetPage)
        if (parseInt(answer) !== (lNumber1 + lNumber2)){
            console.error("Your answer: " + answer + ". Answer is " + (lNumber1 + lNumber2));
            setAnswer('');
            setLNumber1(Math.floor(Math.random() * 45) + 5);
            setLNumber2(Math.floor(Math.random() * 46) + 5);
            return;
        }
        navigate(targetPage, {replace: false});
        dispatch(closeWindow());
    }

    return(<div className={styles.Main}>
        <div className={styles.Question}>{lNumber1} + {lNumber2} = ?</div>
        <div className={styles.Input}>
            <input type="text" pattern="\d*" maxLength="2" id="answer" onChange={(event) => setAnswer(event.target.value)} value={answer}/>
            <div className={styles.Lines}>
                <div />
                <div />
            </div>
        </div>
        <div className={styles.Submit} onClick={onSubmitClicked}><TranslationText value="submit" /></div>
    </div>)
}