import styles from "./BookOverlay.module.css"
import { GetBookProgress } from "../features/Statistics/statisctics.js"
import favOn from "../images/fav-on.svg"
import favOff from "../images/fav-off.svg"
import completedIcon from '../images/completed.png'
import { useDispatch, useSelector } from "react-redux"
import { SaveBookUserData } from "../features/Statistics/statisctics.js"
import { useState } from "react"
import { useEffect } from "react"

export default function BookOverlay({ book, bookUserData }) {
    const accessToken = useSelector((state) => state.user.accessToken);
    const activeUser = useSelector((state) => state.user.activeUser);
    const [favourite, SetFavourite] = useState(false)
    const dispatch = useDispatch();
    useEffect(() =>
    {
        if (bookUserData)
            SetFavourite(bookUserData.favourite)
    }, [bookUserData]);

    if (!activeUser || activeUser.type !== "child") return null;
    const OnClick = (event) => {
        console.log("change");
        if (!bookUserData)
        {
            bookUserData = {
                userId: activeUser.id,
                favourite: false,
                bookId: book.id,
            }
        }
        bookUserData.favourite = !favourite;
        SetFavourite(!favourite);
        SaveBookUserData(dispatch, bookUserData, accessToken);
        event.preventDefault();
        event.stopPropagation();
        return false;
    }

    var hideBookFinishedMark = true;
    if (bookUserData?.pagesVisited && bookUserData?.timeSpent) {
        var progressPages = book.numberOfPages === 0 ? 1 : bookUserData.pagesVisited.length / book.numberOfPages;
        var progessTime = book.narrationTime === 0 ? 1 : Math.min(bookUserData.timeSpent / (book.narrationTime * 0.6), 1);
        if (Math.min(progressPages, progessTime) >= 1) {
            hideBookFinishedMark = false;
        }
    }

    if (!bookUserData)
        return (<div className={styles.Main}>
            <div className={styles.Fav} onClick={OnClick}>
                <img src={favourite ? favOn : favOff} alt=""/>
            </div>
        </div>);
    var progress = GetBookProgress(book, bookUserData);
    var percantage = Math.floor(progress * 100);
    percantage = Math.min(percantage, 101);
    return (<div className={styles.Main}>
        <div className={styles.Icons}>
            <div className={styles.Fav} onClick={OnClick}>
                <img src={favourite ? favOn : favOff} alt=""/>
            </div>
            <div className={styles.ReadFinished} hidden={hideBookFinishedMark}>
                <img src={completedIcon} alt=""/>
            </div>
        </div>
        <div className={styles.ProgressBar} hidden={progress >= 1 || progress <= 0}>
            <div style={{width: percantage + "%" }}></div>
        </div>
    </div>);
}