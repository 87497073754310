import { createSlice } from '@reduxjs/toolkit'

export const databaseSlice = createSlice({
  name: 'database',
  initialState: {
    books: [], 
    terms: [],
    levels: [],
    login: [],
    badges: [],
    uiTexts: [],
    notifications: [],
    bookUserData: [],
  },
  reducers: {
    setBooks: (state, action) => {
        state.books = action.payload;
    },
    setTerms: (state, action) => {
        state.terms = action.payload;
    },
    setLevels: (state, action) => {
        state.levels = action.payload;
    },
    setLogin: (state, action) => {
        state.login = action.payload;
    },
    setBadges: (state, action) => {
        state.badges = action.payload;
    },
    setUITexts: (state, action) => {
        let dictionary = Object.fromEntries(action.payload.map(x => [x.id, x]));
        state.uiTexts = dictionary;
    },
    setNotifications: (state, action) => {
        if (action.payload.length === 0) return;
        //TODO: id merge
        state.notifications = action.payload;
    },
    setBookUserData: (state, action) => {
        var datas = action.payload;
        var userId = datas.length > 0 ? datas[0].userId : undefined;
        state.bookUserData = state.bookUserData.filter(item => item.userId !== userId).concat(datas);
    },
  },
})

// Action creators are generated for each case reducer function
export const { setBooks, setBadges, setBookUserData, setLevels, setLogin, setNotifications, setTerms, setUITexts } = databaseSlice.actions

export default databaseSlice.reducer