import { createSlice } from '@reduxjs/toolkit'

export const classeraWebViewSlice = createSlice({
  name: 'classeraWebView',
  initialState: {
    open: false
  },
  reducers: {
    openClasseraLogin: (state) => {
      state.open = true;
    },

    closeClasseraLogin: (state) => {
      state.open = false;
    },
  },
})

// Action creators are generated for each case reducer function
export const { openClasseraLogin, closeClasseraLogin } = classeraWebViewSlice.actions

export default classeraWebViewSlice.reducer