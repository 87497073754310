export default async function setGeolocation() {

  let currentStoredGeoData = localStorage.getItem("geolocationData");
  
  let mustRefreshGeoData = false;

  if (currentStoredGeoData && JSON.parse(currentStoredGeoData)?.latestSuccessTime) {

    let lastRefreshTime = new Date(JSON.parse(currentStoredGeoData).latestSuccessTime);
    let sevenDaysAfterRefresh = lastRefreshTime.setDate(lastRefreshTime.getDate() + 7);

    let currentDate = new Date();

    if (sevenDaysAfterRefresh <= currentDate) {
      mustRefreshGeoData = true;
    } else {
      mustRefreshGeoData = false;
    }
  } else {
    mustRefreshGeoData = true;
  }

  if (mustRefreshGeoData === true) {  
    await fetch(process.env.REACT_APP_GEO_API_URL)
        .then(response => response.text())
        .then(async data => 
          {
            if (data) {          
                console.log("GEO API detected geolocation: " + data.toString());
    
                let currentDate = new Date();
    
                let currentCountryData = {
                  location: data.toString(),
                  latestSuccessTime: currentDate
                };
    
                localStorage.setItem("geolocationData", JSON.stringify(currentCountryData));
                setWhitelistedAllowedBooks(data.toString());

            }
          })
        .catch(error => console.log('error', error));  
  } else {
    let currentCountryCode = JSON.parse(currentStoredGeoData)?.location;
  
    if (currentCountryCode !== undefined && currentCountryCode !== null) {
      setWhitelistedAllowedBooks(currentCountryCode);
    }
  }

}
  
async function setWhitelistedAllowedBooks(countryCode) {
    await fetch(process.env.REACT_APP_APIBASE + 'mobile/whitelist/' + countryCode)
      .then(response => response.json())
      .then(async data => {
        console.log("whitelist returned " + new Date().toISOString());
        localStorage.setItem("allowedBooks", JSON.stringify(data.result));
      })
      .catch(error => console.log('error', error));
}
