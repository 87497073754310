
import { db } from "./db.js";
import { useLiveQuery } from "dexie-react-hooks";
import { filterBookUserData, filterBookByLevel } from "../search/search.js"
import { setBookUserData } from "./databaseSlice.js"
import { useSelector } from "react-redux"

export function GetBookUserData(userId, bookId) {
    var currentBookUserDatas = useLiveQuery(
        () => db?.bookUserData
            .where({userId: userId ?? "", bookId: bookId  ?? ""})
            .toArray(), [userId, bookId]);
    var stateData = useSelector(state => state.database.bookUserData
      .filter(item => item.userId === userId &&  item.bookId === bookId));
    if (!userId || !bookId)
      return null;
    return db ? (currentBookUserDatas ? currentBookUserDatas[0] : null) : stateData[0];
}

export function GetBookUSerDataCount() {
    var dbData = useLiveQuery(() => db?.bookUserData.count());
    var stateData = useSelector((state) => state.database.bookUserData.length);
    return db ? dbData : stateData;
}

export function GetBookUserDataFiltered(levelId, homeTab, activeUser) {
  var dbData =  useLiveQuery(
        async () => {
          if (homeTab === 0 || homeTab === 2)
            return [];
          const collection = await db?.bookUserData
            .where("userId").equals(activeUser?.id)
            .filter(book => filterBookUserData(book, homeTab))
            .with({ book: 'bookId' });
          return collection ? collection.filter(book => filterBookByLevel(book.book, levelId)) : []
        }, [levelId, homeTab, activeUser]
      );

  var stateBooks = useSelector((state) => state.database.books.filter(book => filterBookByLevel(book, levelId)));
  let dictionary = stateBooks ? Object.fromEntries(stateBooks.map(x => [x.id, x])) : [];

  var stateData = useSelector((state) => 
    state.database.bookUserData.filter(book => 
      book.userId === activeUser?.id && filterBookUserData(book, homeTab)))
  if (stateData && stateBooks)
  {
    for (let id in stateData)
    {
      stateData[id] = { book: dictionary[stateData[id].bookId], ...stateData[id] };
    }
  }
    
  if (homeTab === 0 || homeTab === 2)
    return [];
  return db ? dbData : stateData;
}

export async function SaveBookUserDataToDB(dispatch, userId, list) {
  if (db) await db.bookUserData.where("userId").equals(userId).delete();
  if (db) await db.bookUserData.bulkPut(list);
  dispatch(setBookUserData(list));
}