import BookListHome from '../components/BookListHome'
import Navbar from '../components/Navbar';
import Book from '../pages/Book'
import { useEffect, useMemo } from 'react';
import { useLocation } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';
import { setLevelId } from "../features/search/searchSlice.js"
import { useSelector, useDispatch } from 'react-redux';
import { refreshMe } from "../features/users/login"
import HomeFilters from "../components/HomeFilters"
import BookSlideShow from "../components/BookSlideShow.jsx";
import NewAssignedBooks from '../components/NewAssignedBooks';
import { GetTermByTitle } from "../features/database/terms.js"

function Home() {
    const [searchParams] = useSearchParams();
    let query = useQuery();
    let dispatch = useDispatch();

    const level = searchParams.get("level");
    useEffect(() => {
        async function Refresh(level, dispatch)
        {
            const selected = await GetTermByTitle(level);
            if (selected)
                dispatch(setLevelId(selected.id));
        }
        Refresh(level, dispatch);
    }, [level, dispatch]);

    /* Active User Refresh : Every minute */
    const accessToken = useSelector((state) => state.user.accessToken);
    const MINUTE_MS = 60000;

    useEffect(() => {
        const interval = setInterval(() => {
        
        console.log('Refresh User : Home');

        if (accessToken) {   
            refreshMe(dispatch, accessToken, null);
            console.log("User Refreshed : " + new Date().toLocaleString());
        }

        }, MINUTE_MS);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    const levelId = searchParams.get("levelId");
    if (levelId)
        dispatch(setLevelId(levelId));
    
    useEffect(() => {
        if (searchParams.get("dev") !== null)
        {
            console.log("devlog started")
            window.setTimeout(function() {
                console.log("devlog active")
                var loc='bookrclass://';
                if(searchParams.has('book')){
                    loc='bookrclass://';
                }
                if(searchParams.has('subscription')){
                    loc='bookrclass://supscription';
                }
                loc +=  "?" + searchParams.toString();
                window.location.href = loc;
            }, 1000);
        }
    }, [searchParams])
    if (query.get("book"))
        return(<div>
            <Navbar/>
            <Book/>
            </div>)
    return(<div>
        <Navbar hideLevelSelector={false}/>
        <BookSlideShow/>
        <HomeFilters />
        <BookListHome />
        <NewAssignedBooks />
    </div>)
}

function useQuery() {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
  }

export default Home;