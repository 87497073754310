function LoadingElement() {
    return (
        <div className="d-flex justify-content-center" style={{margin: "20px"}}>
            <div className="spinner-grow text-primary" role="status" id="loader">
                <span className="sr-only"></span>
            </div>
        </div>
    )
}

export default LoadingElement;