import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { SaveNotification } from "../features/database/notifications.js"

export default function LoadingUserData() {

    const accessToken = useSelector((state) => state.user.accessToken);
    const children = useSelector((state) => state.user.children);
    const loggedInUser = useSelector((state) => state.user.loggedInUser);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            try {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", "Bearer " + accessToken);
                var requestOptions = {
                    headers: myHeaders,
                };
              
                const resText = await fetch("https://api.v2.bookrclass.com/api/mobile/notifications", requestOptions);
                const dataText = await resText.json();
                dataText.result.list.forEach(item => item.userId = loggedInUser.id);
                await SaveNotification(dispatch, dataText.result.list);
                console.log("notifications returned " + new Date().toISOString());
            } catch (error) {
            }
        };
        if (accessToken && dispatch)
            fetchData().catch(console.error);
    }, [accessToken, loggedInUser, dispatch]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", "Bearer " + accessToken);
                var requestOptions = {
                    headers: myHeaders,
                };

                for (const child of children)
                {
                    const resText = await fetch("https://api.v2.bookrclass.com/api/mobile/child/"+child.id+"/notifications", requestOptions);
                    const dataText = await resText.json();
                    dataText.result.list.forEach(item => item.userId = child.id);
                    SaveNotification(dispatch, dataText.result.list);
                    console.log("notifications for child returned " + new Date().toISOString());
                }
            } catch (error) {
            }
        };
        if (accessToken && dispatch)
            fetchData().catch(console.error);
    }, [accessToken, children, dispatch]);

    return (<div></div>)
}