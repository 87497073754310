import { db } from "./db.js";
import { useLiveQuery } from "dexie-react-hooks";
import { setNotifications } from "./databaseSlice.js"
import { useSelector } from 'react-redux';

export function GetAllNotReadNotifications() 
{
    var dbData = useLiveQuery(() => db?.notifications.where("type").equals("assign").filter(item => !item.read).toArray());
    var stateData = useSelector(state => state.database.notifications.filter(item => item.type === "assign" && !item.read));
    return db ? dbData : stateData;
}

export async function SaveNotification(dispatch, list)
{
    dispatch(setNotifications(list));
    if (db) await db.notifications.bulkPut(list);
}