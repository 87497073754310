//import Masonry from 'react-masonry-css'
import BookCard from './BookCard'
import React from "react";

import { Masonry } from '@mui/lab';
import { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux'
import styles from "./BookList.module.css"
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LoadingElement from './LoadingElement'
import NoBookFound from "./NoBookFound"
import { HasBook, IsContinues } from '../features/Statistics/statisctics';
import { GetBookUSerDataCount, GetBookUserDataFiltered } from "../features/database/bookUserDataTable.js"
import { GetBooksCount, GetBooksFilteredHome } from "../features/database/booksTable.js"


const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 992,
      lg: 1200,
      xl: 1400,
    },
  },
});

function BookListHome() {
  const [limit, setLimit] = useState(25);

  const levelId = useSelector((state) => state.search.levelId);
  const homeTab = useSelector((state) => state.search.homeTab);
  const activeUser = useSelector((state) => state.user.activeUser);

  const totalBookUserData = GetBookUSerDataCount();
  const totalBooks = GetBooksCount(homeTab);

  const total = (homeTab !== 0 && homeTab !== 2) ? totalBookUserData : totalBooks;
  const books = GetBooksFilteredHome(levelId, homeTab, activeUser);
  const bookUserDatas = GetBookUserDataFiltered(levelId, homeTab, activeUser);

  useEffect(() => {
    setTimeout(() => {
      setLimit(25);
    }, 100);
  }, [homeTab, levelId])


  const intObserver = useRef();
  const lastPostRef = useCallback(post => {
    if (intObserver.current) intObserver.current.disconnect();

    intObserver.current = new IntersectionObserver(posts => {
      if (posts[0].isIntersecting && limit < total) {
        setTimeout(() => {
          setLimit(limit + 25);
        }, 100)
      }
    });

    if (post) intObserver.current.observe(post);
  }, [limit, total])

  var text = [undefined, "the_books_your_teacher_assigned_to_you", undefined, "your_favourite_books_will_appear_here", undefined]

  var content = [];
  if (homeTab === 0 || homeTab === 2)
    content = books?.slice(0,limit)
    .map((book, i) => (<BookCard book={book} bookUserDatas={book.bookUserData} key={book.id} index={i} userId={activeUser?.id} />));
  else if (homeTab === 4)
    content = bookUserDatas?.filter(IsContinues).slice(0,limit)
      .map((bookUserData, i) => (<BookCard book={bookUserData.book} bookUserData={bookUserData} key={bookUserData.book.id} index={i} userId={activeUser?.id}/>));
  else
    content = bookUserDatas?.filter(HasBook).slice(0,limit)
    .map((bookUserData, i) => (<BookCard book={bookUserData.book} bookUserData={bookUserData} key={bookUserData.book.id} index={i} userId={activeUser?.id}/>));
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.water_container}>
        <div className={styles.box_group}>
          {!books || !bookUserDatas ?
              <LoadingElement />
              : (books.length === 0) && (bookUserDatas.length === 0)
              ? (limit < total ? null : (homeTab !== 0 && homeTab !== 2 ? <NoBookFound additional={text[homeTab]} /> : <LoadingElement />))
              : (<Masonry columns={{ xs: 2, sm: 3, md: 4, lg: 6, xl: 6 }} spacing={0}>{content}</Masonry>)}
        </div>
        <div ref={lastPostRef} hidden={!books || limit >= total}>
          {books && limit < total ? (<LoadingElement />) : null}
        </div>
      </div>
    </ThemeProvider>);
}

export default BookListHome;