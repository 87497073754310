import styles from "./SlideShowElement.module.css"
import { useSearchParams } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

export default function SlideShowElement({ book }) {
    //featuredImageUrls
    const [searchParams] = useSearchParams();
    if (searchParams.has("book"))
        searchParams.set("book",book?.id);
    else
        searchParams.append("book", book?.id);
    searchParams.delete("dev");

    const navigate = useNavigate();
    const onClick = async (event) => {
        await new Promise(resolve => {
            setTimeout(() => {
                if (!event.defaultPrevented)
                {
                    navigate("/?" + searchParams.toString(), {replace: false});
                }
                console.log(event.isDefaultPrevented())
                console.log(event)
            }, 100);
          });
    }

    var cover = book.featuredImageUrls?.original_s;
    return (
    <div className={styles.Main} onClick={onClick}>
        <div>
            <img src={cover} alt={book.title}/>
        </div>
      </div>)
}