import { useEffect, useState } from "react"
import styles from "./Loading.module.css"
import { useSearchParams } from 'react-router-dom';
import { updateLevelTermConnection, updateBookSearchString } from '../features/database/syncFunctions.js';
import { useDispatch, useSelector } from 'react-redux';
import { refreshToken } from "../features/users/login.js"
import { setLanguage } from "../features/language/language.js"
import { SaveBadges } from "../features/database/badges.js"
import { SaveTerms } from "../features/database/terms.js"
import { GetAllLogin } from "../features/database/login.js"
import { SaveText } from "../features/database/uiTexts.js"
import { SaveBooks } from "../features/database/booksTable.js"
import setGeolocation from '../features/geolocation/geolocation.js';

export default function Loading() {
    const [fetchDataState, SetFetchDataState] = useState(0);
    const [languageToQuery, SetLanguageToQueryery] = useState(undefined);
    const [error, SetErrors] = useState("");
    const [debug, SetDebug] = useState("");
    const [refreshed, SetRefreshed] = useState(false);
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.language);

    useEffect(() => {
        if (!dispatch) return;
        const localLanguage = localStorage.getItem('language');
        if (localLanguage === "null" || localLanguage === null || !localLanguage) {
            localStorage.setItem("language", language);
        } else if (localLanguage !== language) {
            dispatch(setLanguage(localLanguage));
        }
    }, [dispatch, language]);

    useEffect(() => {
        SetFetchDataState(0);
        SetLanguageToQueryery(language);
    }, [language])

    useEffect(() => {
        const timer = setTimeout(() => {
            const login = GetAllLogin();
            if (refreshed === false && dispatch && login.refresh_token) {
                SetRefreshed(true);
                refreshToken(dispatch, login.refresh_token, (ok) => {
                    if (ok) {
                        console.log("token refershed");
                    }
                }, login.activeUserId)
            }
        }, 100);
        return () => {
            clearTimeout(timer);
        };
    }, [dispatch, refreshed])

    useEffect(() => {
        const fetchData = async (languageToQuery) => {
            try {
                const resText = await fetch("https://api.v2.bookrclass.com/api/mobile/uiTexts?locale=" + languageToQuery);
                const dataText = await resText.json();
                await SaveText(dispatch, dataText.result.list);
                SetDebug("uiTexts returned " + new Date().toISOString());
                console.log("uiTexts returned " + new Date().toISOString());

                const res = await fetch(process.env.REACT_APP_APIBASE + "mobile/books?locale=" + languageToQuery);
                const data = await res.json();
                await SaveBooks(dispatch, data.result.list);
                SetDebug("books returned " + new Date().toISOString());
                console.log("books returned " + new Date().toISOString());

                // Setting the Geolocation and the Whitelisted Books
                await setGeolocation();

                const resTerms = await fetch(process.env.REACT_APP_APIBASE + "mobile/terms?locale=" + languageToQuery);
                const dataTerms = await resTerms.json();
                await SaveTerms(dispatch, dataTerms.result.list.filter(item => item.taxonomyId === 1 || item.taxonomyId === 8));
                SetDebug("bulkPut returned " + new Date().toISOString());
                console.log("bulkPut returned " + new Date().toISOString());
                await updateLevelTermConnection(dispatch);
                SetDebug("updateLevelTermConnection " + new Date().toISOString());
                console.log("updateLevelTermConnection returned " + new Date().toISOString());
                await updateBookSearchString(dispatch);
                SetDebug("updateBookSearchString " + new Date().toISOString());
                console.log("updateBookSearchString returned " + new Date().toISOString());

                const resBadge = await fetch(process.env.REACT_APP_APIBASE + "mobile/badges?locale=" + languageToQuery);
                const dataBadge = await resBadge.json();
                await SaveBadges(dispatch, dataBadge.result.list);
                SetDebug("badges returned " + new Date().toISOString());
                console.log("badges returned " + new Date().toISOString());
                SetFetchDataState(2);
            } catch (error) {
                SetErrors(error);
            }
        };
        if (fetchDataState === 0 && !!languageToQuery && dispatch)
        {
            fetchData(languageToQuery).catch(console.error);
            SetFetchDataState(1);
        }
    }, [fetchDataState, languageToQuery, dispatch]);

    const isDev = (searchParams.get("dev") !== null) && (searchParams.get("dev") !== undefined)
    return (<div
        hidden={!(fetchDataState === 2) || !isDev}
        className={styles.Main}>
        {fetchDataState === 0 ? "Not Started" : fetchDataState === 1 ? "Loading" : "Finished"}{" "}{debug}{" "}{error}
    </div>)
}