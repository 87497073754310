import { useState } from "react";
import styles from "./PrivacyPolicy.module.css";
import TranslationText from './TranslationText.jsx';

export default function PrivacyPolicy({onSubmit}) {

    var [value1, SetValue1] = useState(false);
    var [value2, SetValue2] = useState(false);

    var handleChange1 = (state) => {
        SetValue1(state.target.checked);
    }
    var handleChange2 = (state) => {
        SetValue2(state.target.checked);
    }

    return (<div className={styles.Main}>
        <div className={styles.Title}><TranslationText value="privacy_header" /></div>
        <div className={styles.Policy}><TranslationText value="privacy_policy" enters/></div>
        <div className={styles.PolicyLink}><TranslationText value="privacy_policy_link" rich/></div>
        <div className={styles.Checks}>
            <div className={styles.Check}>
                <input type="checkbox" id="privacy_accept1" name="privacy_accept1" onChange={handleChange1}/>
                <label for="privacy_accept1"  className={styles.Text}><TranslationText value="privacy_accept1" rich/></label>
            </div>
            <div className={styles.Check}>
                <input type="checkbox" id="privacy_accept2" name="privacy_accept2" onChange={handleChange2}/>
                <label for="privacy_accept2"  className={styles.Text}><TranslationText value="privacy_accept2" rich/></label>
            </div>
        </div>
        <div className={styles.Submit} onClick={onSubmit} hidden={!value1 || !value2}>
            <TranslationText value="confirm" />
        </div>
    </div>)
}