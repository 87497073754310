import Dexie from 'dexie';
import relationships from 'dexie-relationships'
import { isPrivateMode } from "./private.js"

export var db;

const checkCookiesEnable = () => {
  let isCookieEnabled = (window.navigator.cookieEnabled) ? true : false;
  if (typeof window.navigator.cookieEnabled == "undefined" && !isCookieEnabled) {
      document.cookie = "testcookie";
      isCookieEnabled = (document.cookie.indexOf("testcookie") !== -1) ? true : false;
  }
  return isCookieEnabled;
}

const init = async () =>
{
  try
  {
    var isIncog = await isPrivateMode();
    if (checkCookiesEnable() && !isIncog)
    {
        db = new Dexie('BookrDatabase' , {addons: [relationships]});
        db.version(27).stores({
          books: 'id, title, coverShortUrls, coverTallUrls, coverUrls, terms, search, numberOfPages, narrationTime',
          terms: 'id, title, taxonomyId',
          levels: 'id, terms',
          badges: 'id, title, iconUrls, streak, bookIds',
          uiTexts: 'id, value',
          notifications: 'id, userId, type',
          bookUserData: 'id, bookId -> books.id, userId, favourite, assignedByTeachers, parentRecommended, pagesVisited, timeSpent, lastPageVisited, readLater',
        });
    } 
    else 
    {
      console.warn("cookies are not enabled");
      db = null;
    }
  } catch (exp)
  {
    console.warn(exp);
    db = null;
  }
}

init();
