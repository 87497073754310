import styles from "./BookSlideShow.module.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CustomSlickDots.css"
import SlideShowElement from "./SlideShowElement";
import { useSelector } from 'react-redux'
import TranslationText from "./TranslationText";
import { GetSlideShowBooks } from "../features/database/booksTable.js"

export default function BookSlideShow() {
    const levelId = useSelector((state) => state.search.levelId);

    const books = GetSlideShowBooks(levelId);

    const settings = {
        slidesToShow: 1,
		centerPadding: '30%',
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		arrows: false,
		dots: true,
		centerMode: true,
		dotsClass: "slick_dots",
		responsive: [{
			breakpoint: 1441,
			settings: {
				slidesToShow: 1,
				centerPadding: '20%',
				dots: true,
			}
		}, {
			breakpoint: 761,
			settings: {
				slidesToShow: 1,
				centerPadding: '0px',
				dots: false,
			}
		}]
      };

    return (
    <div className={styles.Main} hidden={!books || books.length === 0}>
        <div className={styles.Background}></div>
        <Slider {...settings}>
            {!books ? <div><TranslationText value="loading_all_capital" /></div> : books.map(item => (<SlideShowElement book={item} key={item.id}/>))}
        </Slider>
    </div>);
}