import styles from "./Contacts.module.css"
import TranslationText from "./TranslationText"

export default function Contacts() {
    return (
    <div className={styles.Main}>
        <div className={styles.Title}><TranslationText value="for_additional_support"/></div>
        <div className={styles.Block}>
            <div className={styles.Header}><TranslationText value="support_colon_text"/></div>
            <div className={styles.Link}>support@bookrclass.com</div>
        </div>
        <div className={styles.Block}>
            <div className={styles.Header}><TranslationText value="sales_colon_text"/></div>
            <div className={styles.Link}>sales@bookrclass.com</div>
        </div>
    </div>)
}