import { db } from "./db.js";
import { useLiveQuery } from "dexie-react-hooks";
import { setUITexts } from "./databaseSlice.js"
import { useSelector } from 'react-redux';

export function Translate(key)
{
    var text = useLiveQuery(() => !key ? "" : db?.uiTexts.get(key), [key]);
    var textState = useSelector((state) => state.database.uiTexts[key]);
    if (db) return text;
    return textState;
}

export async function SaveText(dispatch, list)
{
    dispatch(setUITexts(list));
    if (db) await db.uiTexts.bulkPut(list);
}