import { createSlice } from '@reduxjs/toolkit'

export const webglSlice = createSlice({
  name: 'webgl',
  initialState: {
    open: false
  },
  reducers: {
    open: (state) => {
      state.open = true;
    },

    close: (state) => {
      state.open = false;
    },
  },
})

// Action creators are generated for each case reducer function
export const { open, close } = webglSlice.actions

export default webglSlice.reducer