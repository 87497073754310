
export async function readNotifications(accessToken, notificationIds) {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-type", "application/json");
    myHeaders.append("Authorization", "Bearer " + accessToken);

    var raw = JSON.stringify({ notificationIds: notificationIds });
  
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    await fetch(process.env.REACT_APP_APIBASE + "mobile/notifications", requestOptions);
  } catch (excpetion) {
    console.error(excpetion);
  }
}