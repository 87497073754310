import styles from "./ProfileDetails.module.css"
import LoadingElement from "./LoadingElement";
import Avatar from "./Avatar"
import { useDispatch, useSelector } from 'react-redux';
import coinIcon from "../images/coin-mobile.svg";
import dayStreakIcon from "../images/day-streak.svg";
import hourIcon from "../images/Vector.svg";
import TranslationText from "./TranslationText"
import editIcon from "../images/edit.svg"
import { openWindow } from "../features/windows/windowSlice.js"

export default function ProfileDetails() {
    const activeUser = useSelector((state) => state.user.activeUser);
    const dispatch = useDispatch();
    if (!activeUser)
        return (<div><LoadingElement/></div>)
    const OnOpen = () => {
        dispatch(openWindow({ type: "avatar", title: "avatar_please_select" }));
    }
    const statisticsBlock = (<div className={styles.Statistics} hidden={!activeUser.statistics}>
        <div>
            <div className={styles.Logo}><img src={dayStreakIcon} alt="dayStreakIcon"/></div>
            <div className={styles.Title}><TranslationText value="dsnew_read_in_a_row" /></div>
            <div className={styles.Value}>{activeUser.statistics?.currentStreak}</div>
        </div>
        <div>
            <div className={styles.Logo}><img src={coinIcon} alt="coinIcon"/></div>
            <div className={styles.Title}><TranslationText value="dsnew_coins_collected" /></div>
            <div className={styles.Value}>{activeUser.statistics?.totalTokenCount}</div>
        </div>
        <div>
            <div className={styles.Logo}><img src={hourIcon} alt="hourIcon"/></div>
            <div className={styles.Title}><TranslationText value="dsnew_reading_minutes" /></div>
            <div className={styles.Value}>{Math.round(activeUser.statistics?.totalReadTime / 60)}</div>
        </div>
    </div>);
    return (<div className={styles.Main}>
        <div className={styles.PorfileContainer} >
            <div className={styles.PorfilePicture}>
                <Avatar big/>
                <img src={editIcon} alt="editAvatar" className={styles.EditIcon} onClick={OnOpen}
                    hidden={activeUser.type !== "child"}/>
            </div>
        </div>
        <div className={styles.Details}>
            <div className={styles.Name} >{activeUser.name}</div>
            <div className={styles.Levels} hidden={!activeUser.level}>
                <div><TranslationText value="level_text" /> {activeUser.level?.title}</div>
                {activeUser.lexileScore ? (<div>|</div>) : null}
                {activeUser.lexileScore ? (<div>{activeUser.lexileScore}L</div>) : null}
                
            </div>
        </div>
        <div className={styles.StatisticsBlock} hidden={!activeUser.statistics}>
            <div className={styles.Line}></div>
            {activeUser.statistics ? statisticsBlock : null}
        </div>
    </div>)
}