import styles from "./BookDetails.module.css"
import { useState } from "react";
import { useDispatch } from 'react-redux'
import { open } from "../features/webgl/webgl.js";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { openWindow } from "../features/windows/windowSlice";
import { GetBookProgress } from "../features/Statistics/statisctics";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../features/database/db";
import { refreshMe } from "../features/users/login";
import BookDetailsNumbers from "./BookDetailsNumbers"
import BookDetailsButtons from "./BookDetailsButtons";
import BookDetailsCover from "./BookDetailsCover";
import { GetBookUserData } from "../features/database/bookUserDataTable.js"
import CryptoJS from "crypto-js"

export default function BookDetails(props) {
    const book = props.book;
    const dispatch = useDispatch();  


    const loggedInUser = useSelector((state) => state.user.activeUser);
    const activeUser = useSelector((state) => state.user.activeUser);

    const accessToken = useSelector((state) => state.user.accessToken);

    const [hasBookOpened, setHasBookOpened] = useState(false);
    const isOpen = useSelector((state) => state.webgl.open);

    /* Maybe this should be fine tuned */
    const isMobile = window.matchMedia('(max-width: 1199px)').matches;

    console.log("isMobile : " + isMobile);

    const bookUserData = GetBookUserData(loggedInUser?.id, book?.id);
    const currentBookUserData = loggedInUser ? bookUserData : null;
    // Refresh needed here
    useEffect(() => {
        //TODO: Refresh Me only works for the loggedInUser
        refreshMe(dispatch, accessToken, null);
    }, [])

    
    const currentBookUserDatas = useLiveQuery(
        () => {
          if (!loggedInUser || !book)
              return null;
          return db.bookUserData
            .where({ userId : loggedInUser.id, bookId: book.id })
            .with({ book: 'bookId' });
        }, [loggedInUser, book]
    );

    function getProgress() {
        if (currentBookUserData && currentBookUserData.pagesVisited?.length !== undefined) {
            return GetBookProgress(book, currentBookUserData);
        }
        else {
            return 0;
        }
    }

    function isBadgeAlreadyClaimed() {
        return GetBookProgress(book, currentBookUserData) >= 0.8;
    }

    useEffect(() => {
        console.log("currentBookUserData : " + JSON.stringify(currentBookUserData));
        if (currentBookUserData && currentBookUserData.pagesVisited?.length !== undefined) {        
            console.log("progress : " + GetBookProgress(book, currentBookUserData));                
            console.log("isBadgeAlreadyClaimed : " + isBadgeAlreadyClaimed());
        }
    }, [currentBookUserData]);

    function Play(){
        dispatch(open()); 
        setHasBookOpened(true);
    }
    function CheckSubscriptionAndPlay(){
        const onRecieved = (success, subscription) => {
            if(success && loggedInUser && subscription) {
                window.location.reload(false);
            } else {
                dispatch(open()); 
                setHasBookOpened(true);
            }
        }
        if (loggedInUser && !loggedInUser.subscription) {
            refreshMe(dispatch, accessToken, onRecieved);
        } else {
            dispatch(open()); 
            setHasBookOpened(true);
        }
    }

    function CreateDeeplinkAndRedirect(){
        let deeplink = ""
        if (!loggedInUser){
            deeplink = `https://bookrclassapp.page.link/?link=https://bookrclassapp.page.link/app/?book=${book?.id}&apn=com.bookrclass.edu&ibi=com.bookrclass.edu&isi=1478717573&ofl=https://bookrclass.com/app?book=${book?.id}`
        } else {
            let encryptedToken = CryptoJS.AES.encrypt(accessToken,  CryptoJS.enc.Utf8.parse(CryptoJS.enc.Base64.parse("V0VUNEIyOTA0TExXRk1URA==").toString(CryptoJS.enc.Utf8)), { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
            encryptedToken = encryptedToken.ciphertext.toString(CryptoJS.enc.Hex).toUpperCase();
            let urlParams = `book=${book?.id}&webLibraryToken=${encryptedToken}&webLibraryUser=${activeUser?.id}`
            let encodedLink = encodeURIComponent(`https://bookrclassapp.page.link/app/?${urlParams}`);
            let encodedOfl = encodeURIComponent(`https://bookrclass.com/app/?${urlParams}`)
            deeplink = `https://bookrclassapp.page.link/?link=${encodedLink}&ofl=${encodedOfl}&apn=com.bookrclass.edu&ibi=com.bookrclass.edu&isi=1478717573`
        }
        window.open(deeplink, '_blank', 'noopener,noreferrer');
    }
    
    const [accumulatedCurrentReadingData, setAccumulatedCurrentReadingData] = useState([]);

    const handleRewarding = () => {
        var hasFinished = hasFinishedCurrentBook(book);
        console.log("has finished current book? : " + hasFinished);
        console.log("isBadgeAlreadyClaimed : " + isBadgeAlreadyClaimed());

        let bookXP = book.experiencePoints;
        let bookBadgeTitle = book?.badges ? JSON.parse(JSON.stringify(book.badges)).title : "";
        let bookBadgeIconUrl = book?.badges ? JSON.parse(JSON.stringify(book.badges)).iconUrls.optimal : "";

        if (hasFinished && !isBadgeAlreadyClaimed()) {
            //dispatch(openWindow({ type: "bookRewards", params: { book: book, XP: bookXP, badgeTitle: bookBadgeTitle, badgeURL: bookBadgeIconUrl }, NoPadding: true, Medium: true}));
            dispatch(openWindow({ type: "bookRewards", params: { book: book, XP: bookXP, badgeTitle: bookBadgeTitle, badgeURL: bookBadgeIconUrl, badgeRequired: true }, NoPadding: true, Medium: true}));
        } else if (hasBookOpened) {
            // here we wanna show the bookRewards without any badges and coins, but only "Similar Books" section should appear here
            dispatch(openWindow({ type: "bookRewards", params: { book: book, XP: bookXP, badgeTitle: bookBadgeTitle, badgeURL: bookBadgeIconUrl, badgeRequired: false }, NoPadding: true, Medium: true}));
        }

        if (hasReachedTwentyMinsReadToday()) {
            dispatch(openWindow({ type: "bookRewards", params: { book: book, XP: bookXP, badgeTitle: bookBadgeTitle, badgeURL: bookBadgeIconUrl, badgeRequired: false, dailyRewardMode: true }, NoPadding: true, Small: true}));
        }
    }
    
    window.addEventListener("message", (event) => {
        if (event.data.toString().includes("bookId")) {

            var receivedReadingData = JSON.parse(JSON.parse(JSON.stringify(event.data)));

            if (receivedReadingData.userId) {
                let hasToPreventMultipleAdd = false;

                accumulatedCurrentReadingData.forEach(checkedStorageData => {
                    if (checkedStorageData?.duration && checkedStorageData?.duration === receivedReadingData.duration && checkedStorageData?.startedAt === receivedReadingData.startedAt) {
                        hasToPreventMultipleAdd = !hasToPreventMultipleAdd;
                        return;
                    }
                });

                if (hasToPreventMultipleAdd) {
                    return;
                }

                let currentReadData = [...accumulatedCurrentReadingData];
                currentReadData.push(receivedReadingData);
                setAccumulatedCurrentReadingData(currentReadData);
            }
        }
    }, false);

    // Handling book close event
    useEffect(() => {

        if ((!isOpen && !isMobile) || (isMobile && accumulatedCurrentReadingData)) {
            handleRewarding();
        }

    }, [isOpen, currentBookUserData, accumulatedCurrentReadingData]);

    const hasFinishedCurrentBook = (book) => {
        let hasFinishedThisTime = false;

        console.log("is accumulated data still reachable in finishCheck : " + JSON.stringify(accumulatedCurrentReadingData));

        console.log("Book closed check finish");

        if (isMobile && accumulatedCurrentReadingData && Array.isArray(accumulatedCurrentReadingData)) {
            var readData = accumulatedCurrentReadingData[0];

            if (readData?.pagesVisited?.length === book.numberOfPages) {
                return true;
            }
        }

        // We have to +1 to the accumulated read data array, since the last page is never added (unless they manually turn pages to another page after visiting last one)

        let totalDuration = 0;

        if (book.numberOfPages <= (accumulatedCurrentReadingData.length + 1)) {

            accumulatedCurrentReadingData.forEach(checkedStorageData => {
                totalDuration += checkedStorageData.duration;
            });

            if (totalDuration >= book.narrationTime * 0.6) {
                hasFinishedThisTime = true;
            }
        }

        return hasFinishedThisTime;
    }

    const hasReachedTwentyMinsReadToday = () => getTotalReadTimeToday() >= 1200;

    const getTotalReadTimeToday = () => {

        let totalTodaysReadDuration = 0;

        if (accumulatedCurrentReadingData.length > 0) {
            accumulatedCurrentReadingData.forEach(checkedStorageData => {
                totalTodaysReadDuration += checkedStorageData.duration;
            });
        }

        return totalTodaysReadDuration;
    }

    return(
    <div className={styles.main}>
        <BookDetailsCover book={book}/>
        <div className={styles.details}>
            <BookDetailsNumbers book={book} />
            <div className={styles.title}>{book.title}</div>
            <div className={styles.description}>{book.description}</div>
            <BookDetailsButtons onClick={() => CheckSubscriptionAndPlay()} book={book} onDeeplinkClick={() => CreateDeeplinkAndRedirect()}/>
        </div>
    </div>)
}
