import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { readNotifications } from "../../features/notifications/notification.js"
import styles from "./AssignedBooksContent.module.css"
import TranslationText from "../../components/TranslationText"
import { useSearchParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { closeWindow } from "../../features/windows/windowSlice.js";
import { GetBook } from "../../features/database/booksTable.js"

export default function AssignedBooksContent({ notifications }) {
    const accessToken = useSelector((state) => state.user.accessToken);
    const [index, setIndex] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!notifications || notifications.length === 0 || notifications.length <= index + 1)
            return;
        const prev = index;
        const timer = setTimeout(() => {
            console.log("new index: " + (index + 1) + "/" + notifications.length)
            if (prev === index)
                setIndex(index + 1);
        }, 3000, [index]);
        return () => {
            clearTimeout(timer);
        };
    }, [notifications, index])

    useEffect(() => {
        if (notifications.length > 0)
            readNotifications(accessToken, notifications.map(item => item.id.toString()))
    }, [notifications, accessToken])

    const bookId = (!notifications || notifications.length === 0) ? null : notifications[index].link.substring(7);
    const book = GetBook(bookId);

    const [searchParams] = useSearchParams();
    if (searchParams.has("book"))
        searchParams.set("book", book?.id);
    else
        searchParams.append("book", book?.id);
    searchParams.delete("dev");
    const navigate = useNavigate();
    const handleClick = () => {
        dispatch(closeWindow());
        navigate("/?" + searchParams.toString(), { replace: false });
    };

    if (!notifications || notifications.length === 0)
        return null;

    return (
        <div>            
            <div className={styles.SubTitle}>
                {notifications[index].from + " assigned you " + book?.title}
            </div>
            <div className={styles.Image}>
                <img src={book?.coverUrls?.original_s} alt="coverImage" />
            </div>
            <div className={styles.SaveChanges} onClick={handleClick}>
                <TranslationText value="read_book" />
            </div>
            <div className={styles.List}>
                {notifications.map((item, i) =>
                    <div className={styles.Toggle + (i === index ? " " + styles.Selected : "")}
                        onClick={() => setIndex(i)} key={i}></div>)}
            </div>
        </div>)
}