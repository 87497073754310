import SearchContainer from '../components/SearchContaier'
import BookList from '../components/BookList'
import Book from '../pages/Book'
import Navbar from '../components/Navbar'
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { setLevelId, setTermId } from "../features/search/searchSlice.js"
import { useSelector, useDispatch } from 'react-redux';
import { refreshMe } from "../features/users/login"
import { GetTermByTitle } from "../features/database/terms.js"

export default function Search() {
    const [searchParams] = useSearchParams();
    let dispatch = useDispatch();

    const topic = searchParams.get("topic");
    useEffect(() => {
        async function Refresh(topic, dispatch)
        {
            const selected = await GetTermByTitle(topic)
            if (selected)
                dispatch(setTermId(selected.id));
        }
        Refresh(topic, dispatch);
    }, [topic, dispatch]);

    /* Active User Refresh : Every minute */
    const accessToken = useSelector((state) => state.user.accessToken);
    const MINUTE_MS = 60000;

    useEffect(() => {
        const interval = setInterval(() => {
        
        console.log('Refresh User : Search');

        if (accessToken) {   
            refreshMe(dispatch, accessToken, null);
            console.log("User Refreshed : " + new Date().toLocaleString());
        }

        }, MINUTE_MS);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    const level = searchParams.get("level");
    useEffect(() => {
        async function Refresh(level, dispatch)
        {
            const selected = await GetTermByTitle(level);
            if (selected)
                dispatch(setLevelId(selected.id));
        }
        Refresh(level, dispatch);
    }, [level, dispatch]);

    const levelId = searchParams.get("levelId");
    if (levelId)
        dispatch(setLevelId(levelId));

    const topicId = searchParams.get("topicId");
    if (topicId)
        dispatch(setTermId(topicId));
    
    useEffect(() => {
        if (searchParams.get("dev") !== null)
        {
            console.log("devlog started")
            window.setTimeout(function() {
                console.log("devlog active")
                var loc='bookrclass://';
                if(searchParams.has('book')){
                    loc='bookrclass://book/'+searchParams.get('book') + "?" + searchParams.toString();
                }
                if(searchParams.has('subscription')){
                    loc='bookrclass://supscription?' + searchParams.toString();
                }
                window.location.href = loc;
            }, 1000);
        }
    }, [searchParams])

    /* Clear Filters on Load */
    dispatch(setLevelId(0));
    dispatch(setTermId(0));

    if (searchParams.get("book"))
        return(<div>
            <Navbar/>
            <Book/>
        </div>)
    return(<div>
        <Navbar hideSearch={true} hideLevelSelector={false}/>
        <SearchContainer/>
        <BookList />
    </div>)
}