import styles from "./Window.module.css"
import closeButton from "../images/close.svg"
import TranslationText from "../components/TranslationText";
import { useDispatch, useSelector } from "react-redux";
import { closeWindow } from "../features/windows/windowSlice.js"
import InterestsContent from "./content/InterestsContent";
import AssignedBooksContent from "./content/AssignedBooksContent";
import AvatarEditWindow from "./content/AvatarEditWindow";
import ParentalGate from "./content/ParentalGate"
import BookRewards from "./content/BookRewards";

export default function Window() {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.windows.data)
    if (!data) return null;
    const { params, type, Small, NoPadding, Medium, title, subTitle } = data;
    var content = null;
    switch(type) {
        case "interests":
            content = <InterestsContent {...params}/>;
            break;
        case "assignedBooks":
            content = <AssignedBooksContent {...params}/>
            break;
        case "avatar":
            content = <AvatarEditWindow {...params}/>
            break;
        case "parentalGate":
            content = <ParentalGate {...params} />
            break;
        case "bookRewards":
            content = <BookRewards {...params} />
            break;
        default:
            content = null;
    }
    return(
    <div className={styles.Main + (NoPadding ? " " + styles.noPaddingOverride : "")}>
        <div className={styles.Window + (Small ? " " + styles.Small: "")  + (NoPadding ? " " + styles.noPaddingOverride : "") + (Medium ? " " + styles.Medium : "")}>
            {title? <div className={styles.Title}><TranslationText value={title} /></div> : ""}
            {subTitle? <div className={styles.SubTitle}><TranslationText value={subTitle} /></div> : ""}
            {content}
            <div className={styles.Close} onClick={() => dispatch(closeWindow())}>
                <img src={closeButton} alt="edit" />
            </div>
        </div>
    </div>)
}