import { Translate } from "../features/database/uiTexts.js";

export default function TranslationText({value, rich, enters}) {
    const text = Translate(value);
    
    var result = text?.value ?? value;
    if (rich)
        return (<div dangerouslySetInnerHTML={{ __html: result }} />);
    if (enters)
    {
      var split = result.split("\\n");
      return split.map((item) => <div>{item}</div>);
    }
    return result;
}