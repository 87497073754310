import React, { useEffect } from 'react';
import { Zoom } from '@mui/material';
import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from "./BookCard.module.css"
import useIsInViewport from '../useIsInViewport';
import {useNavigate} from 'react-router-dom';
import BookOverlay from './BookOverlay';
import { useDispatch } from 'react-redux';
import { closeWindow } from '../features/windows/windowSlice';

const BookCard = React.forwardRef((props, ref) => {
    const ref1 = useRef(null);
    const isInViewport1 = useIsInViewport(ref1);
    const [ isVisible, setVisible ] = useState(false);
    const [searchParams] = useSearchParams();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isVisible && isInViewport1)
        {
            setVisible(true);
        }
    }, 
    [ref1, isVisible, isInViewport1]);

    var book = props.book;
    var isSmall = props.small;
    var index = props.index;
    var bookUserData = props.bookUserData;
    var bookUserDatas = props.bookUserDatas;
    var userId = props.userId;
    if (bookUserData && bookUserData.userId !== userId)
        bookUserData = null;
    if (bookUserDatas)
        bookUserData = bookUserDatas.find(item => item.userId === userId);

    var cover = [book?.coverShortUrls?.original_s, book?.coverUrls?.original_s, book?.coverTallUrls?.original_s];

    let displayAWStyle = props?.displayAWStyle || false;

    if (props?.displayAWStyle) {
        cover = book?.coverUrls?.original_s;
    }

    var selected = isSmall ? [styles.small_min , styles.small_medium, styles.small_max] : [styles.big_min, styles.big_medium, styles.big_max]
    var random = (book.id + index) % 3;
    var imagetype = Math.floor(random);
    if (searchParams.has("book"))
        searchParams.set("book",book?.id);
    else
        searchParams.append("book", book?.id);
    searchParams.delete("dev");
    const navigate = useNavigate();
    const handleClick = () => {
        if (props.closeWindowOnClick) {
            dispatch(closeWindow());
        }
        navigate("/?" + searchParams.toString(), {replace: false});
      };
    return (
        <div ref={ref} className={selected[imagetype] + " " +  styles.Main} >
            <div ref={ref1}>
                <Zoom in={isVisible} style={{ transitionDuration: '100ms'}}>
                <div key="1" className={styles.cover_card} onClick={handleClick}>
                    <img 
                        src={displayAWStyle ? cover : cover[imagetype]} 
                        alt={book.title} 
                        className={displayAWStyle ? styles.book_cover_aw_style : styles.book_cover}
                        decoding={"sync"}
                        loading="eager"
                    />
                    {!displayAWStyle ? <BookOverlay book={book} bookUserData={bookUserData}/> : ""}
                </div>
                </Zoom >
            </div>
        </div>
    )
})

export default BookCard;