
import styles from "./IFrameWrapper.module.css"
import { closeClasseraLogin }from "../features/webgl/classeraWebView.js"
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import leftIcon from "../images/left-icon.svg"

export default function ClasseraIFrameWrapper() {
    const dispatch = useDispatch();
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (dispatch)
            document.addEventListener("keydown", (event) => {
                if(event.key === 'Escape') {
                    dispatch(closeClasseraLogin())
                }
            }, false);
    }, [dispatch])

    const ref1 = useRef(null);
    
    const activeUser = useSelector((state) => state.user.activeUser);
    const accessToken = useSelector((state) => state.user.accessToken);
    const isOpen = useSelector((state) => state.classeraWebView.open);
    
    const isMobile = () => window.matchMedia('(max-width: 1199px)').matches;
    if (accessToken && !searchParams.has("accessToken"))
        searchParams.append("accessToken", accessToken);
    if (activeUser && !searchParams.has("activeUserId"))
        searchParams.append("activeUserId", activeUser.id);

    const iframe = process.env.REACT_APP_CLASSERA_IFRAME;

    const onIFrameLoaded = () => {
        console.log("IFrame location : " + ref1.current.src);
    }

    return (<div className={styles.player} hidden={!isOpen} onClick={() => dispatch(closeClasseraLogin())} >
        <iframe title="Classera SSO Login" src={iframe} ref={ref1} loading="lazy" onLoad={() => onIFrameLoaded()} />
        <img src={leftIcon} alt="backIcon"  onClick={() => dispatch(closeClasseraLogin())} className={styles.lefticon} />
    </div>);
}